import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { CopyTemplateDto, SherpaEntityType, TemplateRequestDto, TemplateResponseDto } from 'src/backend';
import { AppThunk, AppThunkPromise, RootState } from 'src/store';



interface TemplateV2State {
    templates: Record<string, TemplateResponseDto>;
}

const initialState: TemplateV2State = {
    templates: {}
};

export const templateV2Slice = createSlice({
    name: 'templatev2',
    initialState,
    reducers: {
        setTemplates(
            state: TemplateV2State,
            action: PayloadAction<TemplateResponseDto[]>
        ): void {
            action.payload?.forEach((template) => {
                if (!isEqual(state.templates[template.id], template)) {
                    state.templates[template.id] = template;
                }
            });
        },
        deleteTemplate(
            state: TemplateV2State,
            action: PayloadAction<TemplateRequestDto>
        ): void {
            delete state.templates[action.payload.id];
        }
    }
});

const sortTemplates = (templates: TemplateResponseDto[]): TemplateResponseDto[] => {
    return templates.sort((a, b) => String(a.name).localeCompare(String(b.name), 'en', { numeric: true }));
}

export const getV2Templates = (): AppThunk => async (dispatch): Promise<void> => {
    const result = await api.getV2Templates();
    if (result.templates) {
        dispatch(templateV2Slice.actions.setTemplates(sortTemplates(result.templates)));
    }
};

export const createV2Template = (template: TemplateRequestDto): AppThunk => async (dispatch): Promise<void> => {
    const result = await api.createV2Template({ templates: [template] });
    dispatch(templateV2Slice.actions.setTemplates(result.templates));
};

export const updateV2Template = (template: TemplateRequestDto): AppThunk => async (dispatch): Promise<void> => {
    const result = await api.updateV2Template({ templates: [template] });
    dispatch(templateV2Slice.actions.setTemplates(result.templates));
};

export const deleteV2Template = (template: TemplateRequestDto): AppThunk => async (dispatch): Promise<void> => {
    await api.deleteV2Template({ templates: [template] });
    dispatch(templateV2Slice.actions.deleteTemplate(template));
};

export const applyTemplate = ({ templateId, loanId, entityId }: {
    templateId: string,
    loanId: string,
    entityId: string
}): AppThunk => async (): Promise<void> => {
    await api.applyTemplate({ templateId, loanId, entityId });
}

export const copyTemplate = (params: CopyTemplateDto): AppThunkPromise<void> => async (dispatch): Promise<void> => {
    const result = await api.copyTemplate(params);
    dispatch(templateV2Slice.actions.setTemplates(result.templates));
}

export const uploadFieldsCsv = (file: File): AppThunk => async (dispatch): Promise<void> => {
    await api.uploadFieldsCsv({ file });
    dispatch(getV2Templates());
}

export const uploadTemplateCsv = (file: File): AppThunk => async (dispatch): Promise<void> => {
    await api.uploadTemplateCsv({ file });
    dispatch(getV2Templates());
}

export const findByEntityTypeAndLoanId = (entityType: SherpaEntityType, loanId: string): AppThunk => async (dispatch): Promise<void> => {
    const result = await api.findByEntityTypeAndUser({ entityType, loanId });
    dispatch(templateV2Slice.actions.setTemplates(result.templates));
}

export const { reducer, actions } = templateV2Slice;

export const v2TemplateSelector = (id: string) => createSelector(
    (state: RootState) => state[templateV2Slice.name].templates,
    templates => templates[id]);

export const v2TemplatesSelector = (filters: { companyId?: string } = {}) => createSelector((state: RootState) => state[templateV2Slice.name].templates, templates => {
    const templatesList = Object.values(templates);
    if (filters.companyId) {
        return templatesList.filter((template) => template.company?.id === filters.companyId);
    }
    return templatesList;
});