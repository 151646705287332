import { useEffect as s } from "react";
import { $ as t } from "./index-B5chcmfZ.js";
function r(n, e = globalThis == null ? void 0 : globalThis.document) {
  const o = t(n);
  s(() => {
    const a = (d) => {
      d.key === "Escape" && o(d);
    };
    return e.addEventListener("keydown", a), () => e.removeEventListener("keydown", a);
  }, [
    o,
    e
  ]);
}
export {
  r as $
};
