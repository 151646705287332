import { _ as s, $ as n } from "./index-CWsRuimy.js";
import { forwardRef as d, useEffect as p, createElement as $ } from "react";
import { flushSync as m } from "react-dom";
const l = [
  "a",
  "button",
  "div",
  "form",
  "h2",
  "h3",
  "img",
  "input",
  "label",
  "li",
  "nav",
  "ol",
  "p",
  "span",
  "svg",
  "ul"
], x = l.reduce((f, e) => {
  const r = /* @__PURE__ */ d((c, t) => {
    const { asChild: o, ...a } = c, i = o ? n : e;
    return p(() => {
      window[Symbol.for("radix-ui")] = !0;
    }, []), /* @__PURE__ */ $(i, s({}, a, {
      ref: t
    }));
  });
  return r.displayName = `Primitive.${e}`, {
    ...f,
    [e]: r
  };
}, {});
function b(f, e) {
  f && m(
    () => f.dispatchEvent(e)
  );
}
export {
  x as $,
  b as a
};
