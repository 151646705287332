
import { baseApi } from './baseApi';

export const eventApi = baseApi.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
    endpoints: (build) => ({
        createSecureUploadPortalVisit: build.mutation<void, { lenderId: string }>({
            query: (data) => ({
                url: `/v1/noauth/secureUploadVisit/${data.lenderId}`,
                method: 'POST',
            }),
        }),
        createSecureUploadPortalVisitWithSessionId: build.mutation<void, { lenderId: string, sessionId: string }>({
            query: (data) => ({
                url: `/v1/noauth/${data.sessionId}/secureUploadVisit/${data.lenderId}`,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useCreateSecureUploadPortalVisitMutation,
    useCreateSecureUploadPortalVisitWithSessionIdMutation,
} = eventApi;



