import axios, { type GenericAbortSignal } from 'axios';
import type { DeepPartial } from 'global';
import { LoanTemplateFormValues } from 'src/components/loans/templates/template-form/template-form.types';
import { API_BASE } from 'src/constants/api';
import { LOAN_MESSAGES_PER_PAGE } from "src/constants/chat";
import type { ListItem, LoanPhaseListItem } from 'src/constants/lists';
import { DriverLicenseImageType } from "src/constants/person";
import { ActionStatus, HttpHeader, OOPS_MESSAGE, UsStates } from 'src/constants/ui';
import type { ApiResponse, LoanPhaseDto } from "src/types/api";
import type {
    ChangePasswordFormValues,
    ConfirmForgotPasswordRequest,
    ConfirmSignupRequest,
    ForgotPasswordRequest,
    LoginRequest,
    LoginResponse,
    NewPasswordRequest,
    SecurityResponse,
    SignUpRequest
} from "src/types/auth";
import type { Contact, Message, OriginalMessagePayload } from "src/types/chat"
import type { Optional } from 'src/types/common';
import type { LoanEntity } from "src/types/entity";
import type { BulkCopyFormElementRequest, BulkUpdateFormElementRequest, TFormElementPriority } from 'src/types/formelement';
import type { Loan, LoanTemplate, LoanUserDigest } from "src/types/loan"
import type { SearchResult } from "src/types/search"
import type { FieldTask, Task } from "src/types/tasks";
import type { UserPreference } from "src/types/user";
import type { FormElement, IDriverLicenseImage, LoanPackageType, } from "src/types/view"
import { guessFileProviderType } from 'src/utils/document/guess-provider-type';
import { getErrors } from 'src/utils/get-errors';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';
import { getToastDuration } from 'src/utils/get-toast-duration';
import { mapConstantsToList } from 'src/utils/map-constants-to-list';
import { toast } from "src/utils/toast";
import { transformTaskTreeToHTMLTree } from "src/utils/transform-task-to-html-tree";
import { transformTaskTreeToTextTree } from "src/utils/transform-task-to-text-tree";
import { getTasksAsTree } from "src/utils/transform-tasks-to-tree";
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import type * as GeneratedBackend from "../backend";


export interface DocumentUploadResponse {
    name: string;
    s3Key: string;
    url: string;
    id: string;
    downloadUrl: string
}

export type UploadDriverLicenseParams = { individualId: string, file?: File, dto?: GeneratedBackend.IndividualRequestDto, type?: keyof typeof DriverLicenseImageType }
export type APIDriverLicenseInfo = Record<string, string>
export type APIDriverLicense = {
    image: 'DL_BACK';
    state: string;
    number: string;
    expiration: string;
    birthDate: string;
    givenName: string;
    familyName: string;
    address: Record<string, string>;
}
export type APILoan = {
    // TECHDEBT REALESTATE: Create JIRA item for the below fields
    afterRepairValue?: string,
    loanToValue?: string,
    purchasePrice?: string,
    appraisedValue?: string,
    entityName?: string,
    apr: string,
    // TODO ADDRESS: Make this an address fi
    address?: string,
    // TECHDEBT REALESTATE: Also this field
    repairCost?: string,
    id?: string,
    projectName: string,
    loanType: string,
    loanAmount: string,
    closeDate: string,
    loanTermInMonths: string,
    loanPurpose: string,
    templateId?: string,
}

interface FormElementTaskParams {
    loanId: string;
    formElementId: string;
    tasks: FieldTask[];
}

type UrlResponse = {
    url: string;
};


export const get = async <T>(path: string, params?: Record<string, string> | URLSearchParams, { prependApiBase = true, disableToast = false, headers, signal }: { headers?: any, prependApiBase?: boolean, disableToast?: boolean, signal?: GenericAbortSignal } = {}): Promise<T> => {
    const search = new URLSearchParams(params)
    return new Promise(async (resolve, reject) => {
        try {
            const searchString = search.toString() ? `?${search.toString()}` : '';
            const result = await axios.get(`${prependApiBase ? API_BASE : ''}${path}${searchString}`, {
                validateStatus: () => true,
                signal
            });

            if (result?.status >= 400) {
                if (![401, 302].includes(result.status) && !disableToast) {
                    const duration = getToastDuration(OOPS_MESSAGE);
                    toast({
                        type: 'error',
                        content: OOPS_MESSAGE,
                        duration,
                        toastId: 'api-error'
                    });
                }
                try {
                    return reject({
                        ...result.data,
                        status: result.status,
                    });
                } catch (error) {
                    return reject(result);
                }
            }
            try {
                return resolve(result.data)
            } catch (err) {
                if (!disableToast) {
                    const duration = getToastDuration(OOPS_MESSAGE);

                    toast({
                        type: 'error',
                        content: OOPS_MESSAGE,
                        duration,
                        toastId: 'api-error'
                    });

                }
                return resolve({} as any)
            }

        } catch (err) {
            if (err.message !== 'Failed to fetch' && !disableToast) {
                const duration = getToastDuration(OOPS_MESSAGE);

                toast({
                    toastId: 'api-error',
                    type: 'error',
                    content: OOPS_MESSAGE,
                    duration
                });

            }
            return reject(err);
        }
    });
}

export const post = async <T>(path: string, params?: any, { onUploadProgress, headers, disableToast = false, signal }:
    { headers?: any, disableToast?: boolean, signal?: GenericAbortSignal, onUploadProgress?: (percent: number) => void } = {}): Promise<{ data: T, headers: any }> => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(path.includes('http') ? path : `${API_BASE}${path}`, params,
                {
                    onDownloadProgress: (progressEvent) => {
                        if (onUploadProgress) {
                            onUploadProgress(progressEvent.loaded / progressEvent.total * 100)
                        }
                    },
                    validateStatus: () => true,
                    ...headers && { headers },
                    signal
                })
            if (result.status >= 400) {
                let errors = {};
                const message = result.headers[HttpHeader.MY_SHERPAS_MESSAGES];
                const remainingLockedSeconds = result.headers[HttpHeader.MY_SHERPAS_REMAINING_LOCKED_SECONDS]?.replace(/"/g, '');;
                try {
                    errors = result.data;
                } catch (e) { }
                const duration = getToastDuration(message);
                if (!disableToast) {
                    toast({
                        type: 'error',
                        content: message,
                        duration,
                        toastId: 'api-error'
                    });
                }
                reject({
                    errors,
                    headers: {
                        [HttpHeader.MY_SHERPAS_MESSAGES]: "message ?? null",
                        [HttpHeader.MY_SHERPAS_LOCKED_USER]: result.headers[HttpHeader.MY_SHERPAS_LOCKED_USER] ? JSON.parse(result.headers[HttpHeader.MY_SHERPAS_LOCKED_USER]) : undefined,
                        [HttpHeader.MY_SHERPAS_REMAINING_LOCKED_SECONDS]: result.headers[HttpHeader.MY_SHERPAS_REMAINING_LOCKED_SECONDS] ? Number(remainingLockedSeconds) : undefined,
                        [HttpHeader.MY_SHERPAS_PASSWORD_EXPIRED]: result.headers[HttpHeader.MY_SHERPAS_PASSWORD_EXPIRED] ? JSON.parse(result.headers[HttpHeader.MY_SHERPAS_PASSWORD_EXPIRED]) : undefined,
                        [HttpHeader.MY_SHERPAS_ONE_LOGIN_ATTEMPT_LEFT]: result.headers[HttpHeader.MY_SHERPAS_ONE_LOGIN_ATTEMPT_LEFT] ? JSON.parse(result.headers[HttpHeader.MY_SHERPAS_ONE_LOGIN_ATTEMPT_LEFT]) : undefined,
                    },
                    status: result.status
                });
            }
            try {
                const data: T = result.data
                resolve({
                    data,
                    headers: {
                        [HttpHeader.MY_SHERPAS_MESSAGES]: result.headers?.[HttpHeader.MY_SHERPAS_MESSAGES] ?? null,
                    }
                })
            } catch (err) {
                resolve({} as any)
            }

        } catch (err) {
            const duration = getToastDuration(OOPS_MESSAGE);

            if (!disableToast) {
                toast({
                    type: 'error',
                    content: OOPS_MESSAGE,
                    duration,
                    toastId: 'api-error'
                });

            }
            reject(err);
        }
    });
}

export const deleteRequest = async <T>(path: string, data?: any): Promise<{ data: T, headers: any }> => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.delete(path.includes('http') ? path : `${API_BASE}${path}`, {
                data,
                validateStatus: () => true,
            });
            if (result.status >= 400) {
                const message = result.headers[HttpHeader.MY_SHERPAS_MESSAGES];
                const duration = getToastDuration(message);
                toast({
                    type: 'error',
                    content: message,
                    duration,
                    toastId: 'api-error'
                });
                reject({
                    errors: result.data,
                    headers: {
                        [HttpHeader.MY_SHERPAS_MESSAGES]: message
                    }
                });
            }
            try {
                resolve({
                    data: result.data,
                    headers: {
                        [HttpHeader.MY_SHERPAS_MESSAGES]: result.headers[HttpHeader.MY_SHERPAS_MESSAGES] ?? null
                    }
                })
            } catch (err) {
                resolve({} as any)
            }

        } catch (err) {
            const duration = getToastDuration(OOPS_MESSAGE);

            toast({
                type: 'error',
                content: OOPS_MESSAGE,
                duration,
                toastId: 'api-error'
            });

            reject(err);
        }
    });
}

export const put = async <T>(path: string, params?: any, { disableToast = false, signal, onUploadProgress, headers }: { disableToast?: boolean, signal?: GenericAbortSignal, headers?: any, onUploadProgress?: (percent: number) => void } = {}): Promise<{ data: T, headers: any, statusCode: number }> => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.put(path.includes('http') ? path : `${API_BASE}${path}`, params, {
                signal,
                onUploadProgress: (progressEvent) => {
                    if (onUploadProgress) {
                        onUploadProgress(progressEvent.loaded / progressEvent.total * 100)
                    }
                },
                headers,
                validateStatus: () => true,
            })
            if (result?.status >= 400) {

                const message = result.headers?.[HttpHeader.MY_SHERPAS_MESSAGES];
                const errors = result.data;
                const duration = getToastDuration(message);
                if (!disableToast) {
                    toast({
                        type: 'error',
                        content: message,
                        duration,
                        toastId: 'api-error'
                    });
                }

                return reject({
                    errors,
                    headers: {
                        [HttpHeader.MY_SHERPAS_MESSAGES]: result.headers?.[HttpHeader.MY_SHERPAS_MESSAGES] ?? null
                    }
                });
            }
            try {
                resolve({
                    statusCode: result.status,
                    data: result.data,
                    headers: {
                        [HttpHeader.MY_SHERPAS_MESSAGES]: result.headers?.[HttpHeader.MY_SHERPAS_MESSAGES] ?? null
                    }
                })
            } catch (err) {
                const message = OOPS_MESSAGE;
                const duration = getToastDuration(message);
                if (!disableToast) {
                    toast({
                        type: 'error',
                        content: message,
                        duration,
                        toastId: 'api-error'
                    });
                }
                resolve({} as any)
            }

        } catch (err) {

            const message = OOPS_MESSAGE;

            const duration = getToastDuration(message);
            if (!disableToast) {
                toast({
                    type: 'error',
                    content: message,
                    duration,
                    toastId: 'api-error'
                });
            }
            reject(err);
        }
    });
}

class Api {
    async login(info: LoginRequest): Promise<LoginResponse> {
        const { data } = await post<LoginResponse>('/v1/noauth/login', info, { disableToast: true });
        return data;
    }

    /**
      * HTTP GET /api/v2/users/search
      * Java method: com.byzpass.demo.security.AppUserController.search
      */
    async searchUsers(queryParams: { slice: string; }): Promise<GeneratedBackend.AppUserDTO2[]> {
        return get(`/v2/users/search`, queryParams);
    }
    setCookies(cookies: string) {
        axios.defaults.headers.Cookie = cookies;
    }
    async changePassword(values: ChangePasswordFormValues): Promise<any> {
        const { data } = await post<ApiResponse>('/v1/auth/changePassword', values);
        return data;
    }

    async logout(): Promise<any> {
        return post('/auth/logout');
    }

    async resendConfirmationCode(username: string, provider: string): Promise<ApiResponse> {
        const { data } = await post<ApiResponse>('/v1/noauth/resendConfirmationCode', { username, provider });
        return data;
    }

    async authenticate(): Promise<SecurityResponse> {
        try {
            return await get<SecurityResponse>('/v1/auth/isAuthenticated', {}, { disableToast: true });
        } catch (lError) {
            return {
                success: false
            } as SecurityResponse;
        }
    }

    async putUserPreferences(preferences: UserPreference): Promise<string> {
        const { data } = await put<string>('/v1/auth/userSettings', preferences);
        return data;
    }

    async getUserPreferences(): Promise<UserPreference> {
        return get<UserPreference>('/v1/auth/userSettings');
    }

    async newPassword(info: NewPasswordRequest): Promise<ApiResponse> {
        const { data } = await post<ApiResponse>('/v1/noauth/newPassword', info);

        return data;
    }

    async signup(info: SignUpRequest): Promise<ApiResponse> {
        const { data } = await post<ApiResponse>('/v1/noauth/signup', info);

        return data;
    }

    async confirmSignup(info: ConfirmSignupRequest): Promise<ApiResponse> {
        const { data } = await post<ApiResponse>('/v1/noauth/confirmSignup', info);
        return data;

    }

    async forgotPassword(info: ForgotPasswordRequest): Promise<ApiResponse> {
        const { data } = await post<ApiResponse>('/v1/noauth/forgotPassword', info);
        return data;

    }

    async confirmForgotPassword(info: ConfirmForgotPasswordRequest): Promise<ApiResponse> {
        const { data } = await post<ApiResponse>('/v1/noauth/confirmForgotPassword', info, {
            disableToast: true
        });
        return data;
    }

    async doAction(id: string | string[]): Promise<void> {
        await get('/v1/action', {
            id: String(id),
        });
    }

    async search({ query, page, device, contextLoanId }: { query: string; page: number; device: "mobile" | "desktop", contextLoanId?: string }): Promise<SearchResult> {
        const dataResult: SearchResult = await get(`/v1/search`, {
            q: query,
            p: String(page - 1),
            d: device === 'mobile' ? 'm' : 'd',
            ...(contextLoanId && {
                c: contextLoanId
            })
        })

        return dataResult;
    }

    async updateFormElements(formElements: BulkUpdateFormElementRequest[]): Promise<FormElement> {
        const { data } = await put<FormElement>('/v1/formElements/updates', {
            updates: formElements
        });
        return data;
    }

    async copyFormElements(params: BulkCopyFormElementRequest): Promise<FormElement> {
        const { data } = await put<FormElement>('/v1/formElements/copy', params);
        return data;
    }

    async updateFormElementDueDate({ formElementId, dueDate }: { formElementId: string; dueDate: string }): Promise<FormElement> {
        const { data }: { data: FormElement } = await post(`/v1/formElements/${formElementId}/specificDueDate`, { specificDueDate: dueDate })
        return data
    }
    async updateFormElementDisplayOrder({ formElementId, displayOrder }: { formElementId: string; displayOrder: number }): Promise<FormElement> {
        const { data }: { data: FormElement } = await post(`/v1/formElements/${formElementId}/displayOrder`, { displayOrder })
        return data
    }

    async updateFormElementPriority({ formElementId, priority }: { formElementId: string; priority: TFormElementPriority }): Promise<FormElement> {
        const { data }: { data: FormElement } = await post(`/v1/formElements/${formElementId}/priority`, { priority: priority })
        return data
    }

    async postFormElementTitle({ formElementId, title }: { formElementId: string; title: string }): Promise<FormElement> {
        const { data }: { data: FormElement } = await post(`/v1/formElements/${formElementId}/title`, { title })
        return data
    }
    // this is used to check if the loan is modifiable or not
    async postSilentFormElementTitle({ formElementId, title }: { formElementId: string; title: string }): Promise<any> {
        return post(`/v1/formElements/${formElementId}/title`, { title })
    }

    async postApproveFormElement({ formElementId }: { formElementId: string }): Promise<FormElement> {
        const { data }: { data: FormElement } = await post(`/v1/formElements/${formElementId}/approve`, { "approved": true })
        return data
    }

    async postUnApproveFormElement({ formElementId }: { formElementId: string }): Promise<FormElement> {
        const { data }: { data: FormElement } = await post(`/v1/formElements/${formElementId}/approve`, { "approved": false })
        return data
    }

    async getLoan(loanId: string, signal?: GenericAbortSignal): Promise<GeneratedBackend.LoanDto> {
        return get<Loan>(`/v1/loans/${loanId}`, null, {
            disableToast: true,
            signal
        })
    }

    async getEntityLogo(entityId: string): Promise<string> {
        const data: any = await get<UrlResponse>(`/v1/lenders/${entityId}/logo`);
        const dataUrl = data.url;
        return dataUrl;
    }

    async getLoanLogo(loanId: string): Promise<string> {
        const data: any = await get<UrlResponse>(`/v1/loans/${loanId}/logo`);
        const dataUrl = data.url;
        return dataUrl;
    }

    async updateLoanStatus({ loanId, status }: { loanId: string, status: string }) {
        const { data } = await post<Loan>(`/v1/loans/${loanId}/status`, {
            loanStatus: status
        });

        return data;
    }

    async getLoanTemplates(params: any = {}): Promise<LoanTemplate[]> {
        return get<LoanTemplate[]>('/v1/loans/templates', params, { disableToast: true })
    }

    async postLoanTemplate(params: LoanTemplateFormValues): Promise<LoanTemplate> {
        const { data } = await post<LoanTemplate>('/v1/loans/templates', {
            ...params,
            loanType: params.loanType !== '' ? params.loanType : null,
            entityType: params.entityType !== '' ? params.entityType : null,
        }, { disableToast: true });

        return data;
    }

    async putLoanTemplate(params: Partial<LoanTemplateFormValues>): Promise<LoanTemplate> {
        const { data } = await put<LoanTemplate>(`/v1/loans/templates/${params.id}`, params);
        return data;
    }

    async getLoanTemplate(id: string): Promise<LoanTemplate> {
        return get<LoanTemplate>(`/v1/loans/templates/${id}`);
    }

    async getOriginalMessageAsHtml(messageId: string): Promise<string> {
        const { url }: UrlResponse = await get<UrlResponse>(`/v1/messages/originator/${messageId}`);
        const data: OriginalMessagePayload = await get<OriginalMessagePayload>(url, {}, { prependApiBase: false });
        return data.htmlContent;
    }

    async setMessageAsRead(messageId: string): Promise<void> {
        await post(`/v1/messages/${messageId}/read`, null);
    }

    async sendMessage(message: GeneratedBackend.MessageSendDto): Promise<Message> {
        const { data: responseMessage }: { data: GeneratedBackend.MessageDto } = await post<GeneratedBackend.MessageDto>(`/v1/messages`, message);
        if (typeof responseMessage === 'undefined') {
            return null;
        }
        const parseISO = await import('date-fns').then((m) => m.parseISO);
        return ({
            attachmentCount: responseMessage.attachmentCount,
            id: responseMessage.id,
            createdAt: parseISO(responseMessage.processedTime).getTime(),
            body: responseMessage.body,
            originalMessageContentUrl: responseMessage.originalMessageContentUrl,
            subject: responseMessage.subject,
            preview: responseMessage.preview,
            isReadByMe: !!responseMessage.isReadByMe,
            locked: responseMessage.visibleOnlyToMyTeam,
            senderName: getUserDisplayName(responseMessage.from),
            contentType: 'text',
            senderType: !responseMessage.left ? 'user' : 'contact',
            senderId: responseMessage.from?.id,
            from: responseMessage.from,
            toRecipients: responseMessage.toRecipients,
            generationType: responseMessage.generationType,
            labels: responseMessage.labels,
            readAt: responseMessage.isReadByMe,
            formElement: null,
            threadId: responseMessage.messageThread.id,
            isRead: true,
            attachments: []
        }) as Message;
    }

    async getLoanTeamMembers(loanId: string): Promise<Contact[]> {
        const data: any[] = await get(`/v1/loans/${loanId}/teamMembers`, null);
        let contacts: Contact[] = [];
        // if data is an array
        if (Array.isArray(data)) {
            contacts = data?.map((contact: any) => {
                return ({
                    id: contact.id,
                    username: contact.username,
                    left: contact.left,
                    isBorrower: !contact.employer,
                    familyName: contact.familyName,
                    givenName: contact.givenName,
                    name: contact ? getUserDisplayName(contact) : contact.employer?.name
                })
            });
        }
        return contacts
    }
    async getLoanUserTasks({ userId, loanId }: { userId: string, loanId: string }): Promise<Task[]> {
        try {
            const tasks = await get<Task[]>(`/v1/tasks`, {
                userId,
                loanId
            });
            return tasks;
        } catch (error) {
            return [];
        }
    }

    async getTasksAsHtml({ loanId, userId }: { loanId: string, userId: string }): Promise<string> {
        const { html } = await get<{ html: string }>(`/v1/tasks/html`, { loanId, userId });
        return html;
    }

    async getLinkForElement({ formElementId }: { formElementId: string }): Promise<string> {
        try {
            const { url } = await get<{ url: string }>(`/v1/formElements/${formElementId}/link`);
            return url;
        } catch (error) {
            return '';
        }
    }

    async getLoanUserDigest({ userId, loanId }: { userId: string, loanId: string }): Promise<LoanUserDigest> {
        try {
            const data: Record<string, Task[]> = await get<Record<string, Task[]>>(`/v1/tasks`, {
                userId,
                loanId
            });
            let html = ``;
            let text = ``;
            Object.keys(data).forEach(key => {
                const keyPath = key.split('.');
                const taskTitle = keyPath[keyPath.length - 1]?.replace(/([A-Z])/g, ' $1');
                const tree = getTasksAsTree(data[key]);
                text += `
                ${taskTitle}\n
                ${transformTaskTreeToTextTree(tree)}
                `;
                html += `
                ${taskTitle}<br />
                ${transformTaskTreeToHTMLTree(tree)}
                `
            });

            return {
                text,
                html
            }
        } catch {
            return {
                text: ``,
                html: ``
            }
        }

    }
    async getLoanMessages({ loanId, page, perPage, filterByUser }: { loanId?: string, page: number, perPage?: number, filterByUser?: string }): Promise<Message[]> {
        try {
            const data = await get<GeneratedBackend.MessageDto[]>(`/v1/messages`, {
                page: String(page - 1),
                size: perPage ? String(perPage) : String(LOAN_MESSAGES_PER_PAGE),
                ...(filterByUser ? { filterByUser } : {}),
                ...(loanId ? { loanId } : {})
            })
            const parseISO = await import('date-fns').then((m) => m.parseISO);

            const messages: Message[] = data.map((message: GeneratedBackend.MessageDto) => ({
                attachmentCount: message.attachmentCount,
                id: message.id,
                createdAt: parseISO(message.processedTime).getTime(),
                body: message.body,
                originalMessageContentUrl: message.originalMessageContentUrl,
                subject: message.subject,
                preview: message.preview,
                isReadByMe: message.isReadByMe,
                locked: message.visibleOnlyToMyTeam,
                senderName: getUserDisplayName(message.from),
                contentType: 'text',
                senderType: !message.left ? 'user' : 'contact',
                senderId: message.from?.id ?? null,
                from: message.from,
                toRecipients: message.toRecipients,
                generationType: message.generationType,
                labels: message.labels,
                readAt: message.isReadByMe,
                threadId: message.messageThread?.id ?? null,
                formElement: null
            } as any))

            return messages
        } catch (error) {
            console.error(error)
            return []
        }
    }
    getContacts() {
        throw new Error('Method not implemented.')
    }
    async uploadDriverLicenseImage({
        individualId,
        file,
        type,
        dlBackDocumentId,
        dlFrontDocumentId,
        dto = null
    }: {
        individualId: string;
        file?: File;
        dlBackDocumentId: string;
        dlFrontDocumentId: string;
        type: keyof typeof DriverLicenseImageType;
        dto?: GeneratedBackend.IndividualRequestDto;
    }): Promise<IDriverLicenseImage> {
        try {
            let backDocumentId = dlBackDocumentId;
            let frontDocumentId = dlFrontDocumentId;
            if (file) {
                const uploadResult = await this.getDocumentUploadUrl({
                    name: `${type === DriverLicenseImageType.front ? 'US_DL_FRONT' : 'US_DL_BACK'}.${getExtensionFromFilename(file.name)}`,
                    iProviderType: guessFileProviderType(file.name)
                })
                await this.uploadDocumentToS3({
                    url: uploadResult.url,
                    file,
                })
                const data = await this.postUploadedDocumentDetails({
                    ...uploadResult,
                    url: null
                });
                if (type === DriverLicenseImageType.back) {
                    backDocumentId = data.id;
                } else {
                    frontDocumentId = data.id;
                }
            }
            await this.postUpADriversLicense(
                individualId,
                {
                    dl: null,
                    dlBackDocumentId: backDocumentId,
                    dlFrontDocumentId: frontDocumentId,
                    dto
                })

            if (type === DriverLicenseImageType.front) {
                return {
                    front: '',
                    frontUploadStatus: ActionStatus.idle
                }
            } else {
                return {
                    back: '',
                    backUploadStatus: ActionStatus.idle,
                }
            }
        } catch (err) {
            if (type === DriverLicenseImageType.front) {
                return {
                    front: '',
                    frontUploadStatus: ActionStatus.error
                }
            } else {
                return {
                    back: '',
                    backUploadStatus: ActionStatus.error,
                }
            }
        }
    }

    async postCreateLoanPackage({ loanId }: {
        loanId: string
    }) {

        try {
            const queryParams = new URLSearchParams({
                zip: 'true',
                pdf: 'true'
            }).toString();

            const response = await post(`/v1/loans/${loanId}/refreshPackages?${queryParams}`, null)
        } catch (error) {
            console.error(error);
        }
    }

    async getLoanPackage({ loanId, type }: { loanId: string, type: LoanPackageType }) {
        try {
            const data: { pdfUrl: string, zipUrl: string } = await get(`/v1/loans/${loanId}/getPackageUrls`, {
                zip: String(['zip', 'zip_pdf'].includes(type)),
                pdf: String(['pdf', 'zip_pdf'].includes(type))
            })
            const url = data[type + "Package"]
            if (url) {
                if (type === 'pdf') {
                    const newWindow = window.open();
                    newWindow.location = url;
                } else {
                    document.location.href = url;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    async createV2Loan(loan: GeneratedBackend.NewLoanDTO): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>("/v2/loanmanager/createFromTemplate", loan);
        return data
    }


    async updateV2Loan(id: string, loan: GeneratedBackend.UpdateLoanDTO): Promise<GeneratedBackend.LoanDto> {
        const { data } = await put<GeneratedBackend.LoanDto>(`/v1/loans/${id}`, loan);
        return data
    }

    async savePersonDriverLicense(personId: string, driverLicense: APIDriverLicense): Promise<APIDriverLicense> {
        const { data }: { data: APIDriverLicense } = await post(`/v1/persons/${personId}/images/usDriversLicense`, driverLicense);
        return data
    }

    async getJavascriptConstants(): Promise<{
        states: ListItem[],
        loanStatuses: ListItem[],
        loanTypes: ListItem[],
        entityTypes: ListItem[],
        knowledgeBases: ListItem[],
        systemUsersIds: string[],
        priorities: ListItem[],
        phaseCategories: LoanPhaseListItem[],
    }> {
        const data: GeneratedBackend.PerUserConstants = await get(`/v1/javascriptConstants`, null, { disableToast: true });
        return mapConstantsToList(data);
    }

    async getDocumentUploadUrl(args: { name: string; signal?: GenericAbortSignal, iProviderType?: GeneratedBackend.ProviderType; formElementId?: string; loanId?: string; }): Promise<GeneratedBackend.Upload> {
        const { signal, ...rest } = args;
        return get(`/v1/documents/uploadUrlFor`, rest, {
            signal,
            disableToast: true
        })
    }

    async uploadDocumentToS3(args: { signal?: GenericAbortSignal, providerType?: GeneratedBackend.ProviderType, url: string, file: File, onUploadProgress?: (percent: number) => void }): Promise<void> {
        await put(args.url, args.file, {
            signal: args.signal,
            disableToast: true,
            onUploadProgress: args.onUploadProgress,
            ...(args.providerType === "SHAREPOINT" && {
                headers: {
                    "Content-range": `bytes 0-${args.file.size - 1}/${args.file.size}`,
                }
            })
        });
    }

    async postUploadedDocumentDetails(payload: GeneratedBackend.Upload, signal?: GenericAbortSignal): Promise<DocumentUploadResponse> {
        const { data }: { data: DocumentUploadResponse } = await post('/v1/documents', payload,
            {
                signal,
                disableToast: true
            })
        return data;
    }

    async postAnswerFormElement({ formElementId, payload }: { formElementId: string, payload: any }): Promise<FormElement> {
        const { data } = await post<FormElement>(`/v1/formElements/${formElementId}/answer`, payload)

        return data
    }

    async deleteFormElementAnswer({ formElementId }: { formElementId: string }): Promise<FormElement> {
        const { data } = await deleteRequest<FormElement>(`/v1/formElements/${formElementId}/answer`)

        return data
    }

    async deleteFormElement({ formElementId }: { formElementId: string }): Promise<FormElement> {
        const { data } = await deleteRequest<FormElement>(`/v1/formElements/${formElementId}`)

        return data
    }

    async deleteFormElements({ idsToDelete }: { idsToDelete: string[] }): Promise<FormElement> {
        const { data } = await deleteRequest<FormElement>(`/v1/formElements/deletes`, { idsToDelete })
        return data
    }

    async postChildFormElement({ formElementId, payload }: { formElementId: string, payload: any }): Promise<{ data?: GeneratedBackend.FormElementV2ResponseDto, errors?: Record<string, string> }> {
        try {
            const { data } = await post<GeneratedBackend.FormElementV2ResponseDto>(`/v1/formElements/${formElementId}/${!payload?.copyFromTemplateId ? 'children' : 'executeTemplate'}`, payload);
            return { data };
        } catch (error) {
            const errors = getErrors(error?.errors?.fieldErrors)
            return { errors };
        }
    }

    async postAddTemplate({ formElementId, payload }: { formElementId: string, payload: any }): Promise<{ data?: FormElement, errors?: Record<string, string> }> {
        try {
            const { data } = await post<FormElement>(`/v1/formElements/${formElementId}/addTemplate`, payload);
            return { data };
        } catch (error) {
            const errors = getErrors(error?.errors?.fieldErrors)
            return { errors };
        }
    }

    async putFormElement({ formElementId, payload }: { formElementId: string, payload: any }): Promise<{ data?: FormElement, errors?: Record<string, string>, statusCode?: number }> {
        try {
            const { data, statusCode } = await put<FormElement>(`/v1/formElements/${formElementId}`, payload);
            return { data, statusCode };
        } catch (error) {
            const errors = getErrors(error?.errors?.fieldErrors)
            return { errors };
        }
    }

    async postAssignFormElementToUser({ formElementId, userId, assignRecursively = true }: { formElementId: string, userId: string, assignRecursively: boolean }): Promise<FormElement> {
        const { data }: { data: FormElement } = await post(`/v1/formElements/${formElementId}/assignedToUser?assignRecursively=${assignRecursively}`, { assignedToUserId: userId })
        return data
    }

    async postResetFormElementAnswer({ formElementId }: { formElementId: string }): Promise<FormElement> {
        const { data } = await post<FormElement>(`/v1/formElements/${formElementId}/answerReset`, {});

        return data;
    }

    async getDocumentDownloadUrl(documentId: string, params?: { iProviderType?: GeneratedBackend.ProviderType, formElementId?: string, loanId?: string }): Promise<string> {
        const { downloadUrl }: any = await get(`/v1/documents/${documentId}/withDownloadUrl`, params)
        // The returns the download url with port 5000 , now that we have started
        // to proxy all requests via frontend the port shouldbe 3000
        // I am replacing here temporarly until the change is made server side
        const tempReplaceDownloadUrl = downloadUrl.replace('5000', '3000');
        return tempReplaceDownloadUrl;
    }

    async getUSStates(): Promise<ListItem[]> {

        try {
            const data: any = await get(`/v1/javascriptConstants`, null);
            const statesKeys = Object.keys(data.statesMap)
            const items: ListItem[] = statesKeys.map(key => (
                {
                    label: statesKeys[key].unabbreviated,
                    value: key
                }
            ))
            return items
        } catch (err) {
            const statesKeys = Object.keys(UsStates)
            const items: ListItem[] = statesKeys.map(key => (
                {
                    label: UsStates[key].unabbreviated,
                    value: key
                }
            ))
            return items
        }
    }

    async postApplicant(loanId: string, entity: LoanEntity): Promise<LoanEntity | { errors?: Record<string, string>, message?: string }> {
        try {
            const { data } = await post<LoanEntity>(`/v1/loans/${loanId}/createAndSetApplicant`, entity);
            return data;
        } catch (e) {
            const errors = getErrors(e?.errors?.fieldErrors)
            return { errors, message: e.headers[HttpHeader.MY_SHERPAS_MESSAGES] }
        }
    }
    async postAddEntity(loanId: string, entity: LoanEntity): Promise<LoanEntity | { errors?: Record<string, string>, message?: string }> {
        try {
            const { data } = await post<LoanEntity>(`/v1/loans/${loanId}/addEntity`, entity);
            return data;
        } catch (e) {
            const errors = getErrors(e?.errors?.fieldErrors)
            return { errors, message: e.headers[HttpHeader.MY_SHERPAS_MESSAGES] }
        }
    }

    /**
     * HTTP POST /api/v2/users/{userId}/creditConsent
     * Java method: com.byzpass.demo.security.AppUserController.addSoftCreditPull
     */
    async addSoftCreditPull(userId: string, iNewCreditConsentDTO: GeneratedBackend.NewCreditConsentDTO): Promise<GeneratedBackend.CreditConsent> {
        const { data } = await post<GeneratedBackend.CreditConsent>(`/v2/users/${userId}/creditConsent`, iNewCreditConsentDTO);
        return data;
    }

    async addLender(id: string, lenderUser: GeneratedBackend.LeadUserSetDto): Promise<Loan> {
        const { data } = await post<Loan>(`/v1/loans/${id}/lenderUser`, lenderUser);
        return data;
    }

    /**
     * HTTP PUT /api/v1/loans/{loanId}/lenderUser
     * Java method: com.byzpass.demo.loan.LoanController.editLender
     */
    async editLender(loanId: string, lenderUser: GeneratedBackend.LeadUserSetDto): Promise<GeneratedBackend.LoanDto> {
        const { data } = await put<GeneratedBackend.LoanDto>(`/v1/loans/${loanId}/lenderUser`, lenderUser);
        return data;
    }

    async removeLender(id: string, roleId: string): Promise<Loan> {
        const { data } = await deleteRequest<Loan>(`/v1/loans/${id}/lenderUser/${roleId}`);
        return data;
    }

    async getCompanyLoanPhases({ companyId }: { companyId: string }): Promise<LoanPhaseDto[]> {
        return await get<LoanPhaseDto[]>(`/v1/lenders/${companyId}/phases`);
    }

    async createOrUpdateCompanyLoanPhases({ phases }: { phases: Optional<LoanPhaseDto, 'id'>[] }): Promise<void> {
        await post<LoanPhaseDto>(`/v1/lenders/phases`, phases);
    }

    async deleteCompanyLoanPhases({ phases }: { phases: Pick<LoanPhaseDto, 'id'>[] }): Promise<void> {
        await deleteRequest(`/v1/lenders/phases`, phases);
    }

    async updateLoanPhase({ loanId, phaseId }: { loanId: string, phaseId: string }): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>(`/v1/loans/${loanId}/phase`, { id: phaseId });
        return data;
    }

    async getCompanyEmployees({ lenderId }: { lenderId: string }): Promise<GeneratedBackend.AppUserDTO2[]> {
        const data = await get<GeneratedBackend.CompanyEmployeeListDto>(`/v1/lenders/${lenderId}/employees`);

        return data.employees;
    }

    async getCompanyLabels({ lenderId }: { lenderId: string }): Promise<GeneratedBackend.SimpleLabelDto[]> {
        const data = await get<GeneratedBackend.LabelsListDto>(`/v1/admin/labels/forCompany/${lenderId}`);

        return data.labels;
    }

    async putCompanyLabels({ lenderId, labels }: { lenderId: string, labels: GeneratedBackend.LabelsListDto }): Promise<void> {
        await post(`/v1/admin/labels/forCompany/${lenderId}`, labels);
    }

    async deleteCompanyLabels({ labelIds }: { labelIds: string[] }): Promise<void> {
        await deleteRequest(`/v1/admin/labels`, { toDelete: labelIds });
    }

    /**
     * HTTP PUT /api/v1/transunion/reeschedule/{loanId}/{individualId}
     * Java method: com.byzpass.demo.transunion.v2.TransunionController.reescheduleReport
     */
    async rescheduleReport(loanId: string, individualId: string, queryParams?: { forceSchedule?: boolean; }): Promise<void> {
        put(`/v1/transunion/reeschedule/${loanId}/${individualId}`, queryParams);
    }

    async putLabelOnSomeone(loanId: string, params: GeneratedBackend.SetLabelOnUserDto): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>(`/v1/labels/loan/${loanId}/setForUser`, params);
        return data
    }

    async setUserRole(loanId: string, lenderUser: GeneratedBackend.LeadUserSetDto): Promise<Loan> {
        const { data } = await post<Loan>(`/v1/loans/${loanId}/borrowerRole`, lenderUser);
        return data;
    }

    // templates v2
    async createV2Template(templates: GeneratedBackend.TemplatesRequestDto): Promise<GeneratedBackend.TemplatesResponseDto> {
        const { data } = await post<GeneratedBackend.TemplatesResponseDto>(`/v2/templates`, templates);
        return data;
    }
    async updateV2Template(templates: GeneratedBackend.TemplatesRequestDto): Promise<GeneratedBackend.TemplatesResponseDto> {
        const { data } = await put<GeneratedBackend.TemplatesResponseDto>(`/v2/templates`, templates);
        return data;
    }

    async deleteV2Template(templates: GeneratedBackend.TemplatesRequestDto): Promise<GeneratedBackend.TemplatesResponseDto> {
        const { data } = await deleteRequest<GeneratedBackend.TemplatesResponseDto>(`/v2/templates`, templates);
        return data;
    }

    async getV2Templates(): Promise<GeneratedBackend.TemplatesResponseDto> {
        return get<GeneratedBackend.TemplatesResponseDto>(`/v2/templates`);
    }

    async getV2TemplateElements({ templateId }: { templateId: string }): Promise<Record<string, GeneratedBackend.TemplateElementResponseDto>> {
        const data = await get<GeneratedBackend.TemplateElementsResponseDto>(`/v2/templates/${templateId}/elements`);
        return data.elements;
    }

    async getV2TemplateTypes(): Promise<GeneratedBackend.TemplateTypeMapsDto> {
        const data = await get<GeneratedBackend.TemplateTypeMapsDto>(`/v2/templates/types`);
        return data;
    }

    async createV2TemplateElements(elements: GeneratedBackend.TemplateElementsRequestDto): Promise<Record<string, GeneratedBackend.TemplateElementResponseDto>> {
        const { data } = await post<GeneratedBackend.TemplateElementsResponseDto>(`/v2/templates/elements`, elements);
        return data.elements;
    }

    async updateV2TemplateElements(elements: { elements: Partial<GeneratedBackend.TemplateElementRequestDto>[] }): Promise<Record<string, GeneratedBackend.TemplateElementResponseDto>> {
        const { data } = await put<GeneratedBackend.TemplateElementsResponseDto>(`/v2/templates/elements`, elements);
        return data.elements;
    }
    async deleteV2TemplateElements(elements: GeneratedBackend.TemplateElementsRequestDto): Promise<void> {
        await deleteRequest<GeneratedBackend.TemplateElementsResponseDto>(`/v2/templates/elements`, elements);
    }
    async answerV2TemplateElement(answer: GeneratedBackend.AnswerTemplateElementDto): Promise<GeneratedBackend.TemplateElementResponseDto> {
        const { data } = await post<GeneratedBackend.TemplateElementResponseDto>('/v2/templates/elements/answer', answer);

        return data;
    }

    async uploadDocument(args: { file: File, name?: string, signal?: GenericAbortSignal, onUploadProgress?: (percent: number) => void, loanId?: string, formElementId?: string }): Promise<DocumentUploadResponse> {
        const documentName = args.name ? `${args.name}.${getExtensionFromFilename(args.file.name)}` : args.file.name;
        const providerType = guessFileProviderType(documentName);
        const urlData = await this.getDocumentUploadUrl({
            signal: args.signal,
            name: documentName,
            iProviderType: providerType,
            formElementId: args.formElementId ?? '',
            loanId: args.loanId ?? ''
        });
        await this.uploadDocumentToS3({
            signal: args.signal,
            url: urlData.url,
            file: args.file,
            providerType,
            onUploadProgress: args.onUploadProgress
        });
        const documentData = await this.postUploadedDocumentDetails({
            ...urlData,
            url: null
        }, args.signal);
        return documentData
    }

    // v2 form elements
    async getV2FormElements({ loanId, loanViewType = 'CONVENTIONAL', signal }: { loanId: string, loanViewType?: GeneratedBackend.LoanViewType, signal?: GenericAbortSignal }): Promise<GeneratedBackend.FormElementsV2ResponseDto> {
        const data = await get<GeneratedBackend.FormElementsV2ResponseDto>(`/v2/loanmanager/${loanId}/elements`, {
            view: loanViewType
        }, {
            signal,
            disableToast: true
        });
        return data;
    }

    async deleteV2FormElements(params: GeneratedBackend.FormElementsRequestDto): Promise<void> {
        await deleteRequest('/v2/loanmanager/elements', params);
    }

    async updateV2FormElements(params: {
        multiSelect: boolean,
        elements: Partial<GeneratedBackend.FormElementV2RequestDto>[]
    }): Promise<Record<string, GeneratedBackend.FormElementV2ResponseDto>> {
        const { data } = await put<GeneratedBackend.FormElementsV2ResponseDto>('/v2/loanmanager/elements', params);
        return data.elements;
    }

    async createV2FormElements(params: {
        elements: Partial<GeneratedBackend.FormElementV2RequestDto>[]
    }): Promise<GeneratedBackend.FormElementsV2ResponseDto> {
        const { data } = await post<GeneratedBackend.FormElementsV2ResponseDto>('/v2/loanmanager/elements', params);
        return data;
    }

    async applyTemplate(request: GeneratedBackend.ApplyTemplateDto): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>('/v2/loanmanager/applyTemplate', request);
        return data;
    }

    async uploadTemplateCsv(params: { file: File; }): Promise<void> {
        await post('/v2/templates/upload', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    async uploadFieldsCsv(params: { file: File; }): Promise<void> {
        await post('/v2/templatefields/upload', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    async addAnswerToV2Element(params: GeneratedBackend.AnswerFormElementDto, disableToast = true): Promise<GeneratedBackend.FormElementV2ResponseDto> {
        const { data } = await post<GeneratedBackend.FormElementV2ResponseDto>('/v2/loanmanager/elements/answer', params, {
            disableToast
        });
        return data;
    }

    async deleteAnswerFromV2Element(params: GeneratedBackend.AnswerFormElementDto): Promise<void> {
        await deleteRequest('/v2/loanmanager/elements/answer', params);
    }

    async createAssets(params: GeneratedBackend.EntityCreateDto): Promise<GeneratedBackend.EntityResponseDto> {
        const { data } = await post<GeneratedBackend.EntityResponseDto>('/v2/entities/assets', params);
        return data;
    }

    async createRealEstateAssets(params: GeneratedBackend.EntityCreateDto): Promise<GeneratedBackend.EntityResponseDto> {
        const { data } = await post<GeneratedBackend.EntityResponseDto>('/v2/entities/realEstate', params);
        return data;
    }

    async createBusinesses(params: GeneratedBackend.EntityCreateDto): Promise<GeneratedBackend.EntityResponseDto> {
        const { data } = await post<GeneratedBackend.EntityResponseDto>('/v2/entities/businesses', params);
        return data;
    }

    async addEntityRealEstateInformation(entityId: string, information: GeneratedBackend.RealEstateRequestDto): Promise<void> {
        await post(`/v2/information/realEstate/${entityId}`, information);
    }

    async getEntityRealEstateInformation(entityId: string): Promise<GeneratedBackend.RealEstateResponseDto> {
        const data = await get<GeneratedBackend.RealEstateResponseDto>(`/v2/information/realEstate/${entityId}`, null, {
            disableToast: true
        });
        return data;
    }

    async getEntityBusinessInformation(entityId: string): Promise<GeneratedBackend.EntityResponseDto> {
        const data = await get<GeneratedBackend.EntityResponseDto>(`/v2/information/business/${entityId}`, null, {
            disableToast: true
        });
        return data;
    }

    /**
     * HTTP PUT /api/v1/clear/schedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.scheduleBusinessQuickFlagReport
     */
    async scheduleBusinessQuickFlagReport(loanId: string, entityId: string): Promise<void> {
        await put(`/v1/clear/schedule/businessquickflag/${loanId}/${entityId}`);
    }

    /**
     * HTTP PUT /api/v1/clear/reeschedule/individualquickflag/{loanId}/{individualId}
     * Java method: com.byzpass.demo.clear.ClearController.reescheduleIndividualQuickFlagReport
     */
    async reescheduleIndividualQuickFlagReport(loanId: string, individualId: string): Promise<void> {
        await put(`/v1/clear/reeschedule/individualquickflag/${loanId}/${individualId}`);
    }

    /**
     * HTTP PUT /api/v1/clear/reschedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.reescheduleBusinessQuickFlagReport
     */
    async reescheduleBusinessQuickFlagReport(loanId: string, entityId: string): Promise<void> {
        await put(`/v1/clear/reschedule/businessquickflag/${loanId}/${entityId}`);
    }

    async getShoeBoxItemsByLoan(params: { loan: string; }): Promise<GeneratedBackend.ShoeboxItemResponseDto[]> {
        const data = await get<GeneratedBackend.ShoeboxItemResponseDto[]>('/v2/shoebox/loan', params);
        return data;
    }

    async createShoeBoxItem(params: GeneratedBackend.ShoeboxItemRequestDto): Promise<GeneratedBackend.ShoeboxItemResponseDto> {
        const { data } = await post<GeneratedBackend.ShoeboxItemResponseDto>('/v2/shoebox/item', params);
        return data
    }

    async deleteShoeBoxItem(params: GeneratedBackend.ShoeboxItemRequestDto): Promise<void> {
        await deleteRequest('/v2/shoebox/item', params);
    }

    async renameEntity(entityId: string, params: GeneratedBackend.RenameEntityDto): Promise<void> {
        await put(`/v2/entities/${entityId}/rename`, params);
    }

    async postBusinessEntityInformation(entityId: string, information: GeneratedBackend.BusinessRequestDto): Promise<void> {
        await post(`/v2/information/business/${entityId}`, information);
    }

    /**
      * HTTP GET /api/v2/loanmanager/linkForLoan/{packageId}
      * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLinkForElementV2
      */
    async getLinkForElementV2(elementId: string): Promise<string> {
        const data = await get<GeneratedBackend.FormElementUrlResponseDto>(`/v2/loanmanager/linkForLoan/${elementId}`);

        return data.url;
    }
    /**
         * HTTP POST /api/v2/loanmanager/entityLabel
         * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.addEntityLabel
         */
    async addEntityLabel(params: GeneratedBackend.EntityLabelDto): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>(`/v2/loanmanager/entityLabel`, params);
        return data;
    }
    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/companyUser
     * Java method: com.byzpass.demo.company.LenderController.onboardCompanyUser
     */
    async onboardCompanyUser(toOnboard: GeneratedBackend.OnboardUserDto): Promise<{ data: void, headers: any }> {
        return post<void>('/v1/lenders/companyUser', toOnboard);
    }

    /**
     * HTTP POST /api/v1/loans/{loanId}/borrowerUser
     * Java method: com.byzpass.demo.loan.LoanController.addBorrowerUser
     */
    async addBorrowerUser(loanId: string, iBorrowerUser: GeneratedBackend.BorrowUserSetDto): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>(`/v1/loans/${loanId}/borrowerUser`, iBorrowerUser);
        return data;
    }

    /**
     * HTTP POST /api/v1/auth/addUser
     * Java method: com.byzpass.demo.security.SecurityAuthController.addUser
     */
    async addUser(iSignupRequestDto: GeneratedBackend.AppUserSignupRequestDto): Promise<GeneratedBackend.AppUserDTO2> {
        const { data } = await post<GeneratedBackend.AppUserDTO2>('/v1/auth/addUser', iSignupRequestDto);
        return data;
    }

    /**
    * HTTP GET /api/v1/action/pendingInvites
    * Java method: com.byzpass.demo.linkaction.LinkActionController.getPendingInvites
    */
    async getPendingInvites(): Promise<GeneratedBackend.LinkActionInviteToLoanResponseDto[]> {
        const data = await get<GeneratedBackend.LinkActionInviteToLoanResponseDto[]>(`/v1/action/pendingInvites`);
        return data;
    }

    /**
     * HTTP POST /api/v2/tasks/element/{id}
     * Java method: com.byzpass.demo.task.TaskController2.tasksForV2Element
     */
    async postTasksForV2Element(id: string, iNewTaskDtos: GeneratedBackend.NewTaskDto[]): Promise<GeneratedBackend.NewTaskResponseDto2[]> {
        const { data } = await post<GeneratedBackend.NewTaskResponseDto2[]>(`/v2/tasks/element/${id}`, iNewTaskDtos);
        return data;
    }

    /**
     * HTTP POST /api/v2/loanmanager/elements/{id}/resetAnswer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.resetTemplateAnswerOnElement
     */
    async resetTemplateAnswerOnElement(id: string, params: { id: string; }): Promise<void> {
        await post(`/v2/loanmanager/elements/${id}/resetAnswer?id=${params.id}`);
    }

    /**
     * HTTP POST /api/v2/templates/copy
     * Java method: ai.mysherpas.app.templates.TemplatesController.copyTemplate
     */
    async copyTemplate(iDto: GeneratedBackend.CopyTemplateDto): Promise<GeneratedBackend.TemplatesResponseDto> {
        const { data } = await post<GeneratedBackend.TemplatesResponseDto>('/v2/templates/copy', iDto);
        return data;
    }

    /**
     * HTTP POST /api/v1/auth/editUser/{id}
     * Java method: com.byzpass.demo.security.SecurityAuthController.editUser
     */
    async editUser(id: string, iSignupRequestDto: GeneratedBackend.AppUserSignupRequestDto): Promise<GeneratedBackend.AppUserDTO2> {
        const { data } = await post<GeneratedBackend.AppUserDTO2>(`/v1/auth/editUser/${id}`, iSignupRequestDto);

        return data;
    }

    /**
     * HTTP GET /api/v2/templates/borrower
     * Java method: ai.mysherpas.app.templates.TemplatesController.findByEntityTypeAndUser
     */
    async findByEntityTypeAndUser(queryParams: { entityType: GeneratedBackend.SherpaEntityType; loanId: string; }): Promise<GeneratedBackend.TemplatesResponseDto> {
        const data = await get<GeneratedBackend.TemplatesResponseDto>(`/v2/templates/borrower`, queryParams);
        return data;
    }

    async editBorrowUser(loanId: string, userId: string, iAppUserNewData: GeneratedBackend.BorrowUserSetDto): Promise<GeneratedBackend.AppUserDTO2> {
        const { data } = await post<GeneratedBackend.AppUserDTO2>(`/v1/loans/editBorrowUser/${loanId}/${userId}`, iAppUserNewData);
        return data;
    }

    /**
     * HTTP POST /api/v2/loanmanager/elements/copy
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.copyElements
     */
    async copyElements(iDto: GeneratedBackend.CopyFormElementsDto): Promise<GeneratedBackend.FormElementsV2ResponseDto> {
        const { data } = await post<GeneratedBackend.FormElementsV2ResponseDto>(`/v2/loanmanager/elements/copy`, iDto);

        return data;
    }

    /**
     * HTTP DELETE /api/v2/loanmanager/{loanId}/entity/{entityId}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.removeEntityFromLoan
     */
    async removeEntityFromLoan(loanId: string, entityId: string): Promise<GeneratedBackend.LoanDto> {
        const { data } = await deleteRequest<GeneratedBackend.LoanDto>(`/v2/loanmanager/${loanId}/entity/${entityId}`);
        return data;
    }

    /**
     * HTTP GET /api/v2/information/individual/{entityId}/riskreportstatus/{loanId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getIndividualInfo
     */
    async getIndividualInfo(entityId: string, loanId: string): Promise<GeneratedBackend.IndividualResponseDto> {
        return get<GeneratedBackend.IndividualResponseDto>(`/v2/information/individual/${entityId}/riskreportstatus/${loanId}`);
    }

    /**
     * HTTP POST /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpADriversLicense
     */
    async postUpADriversLicense(individualId: string, dto: GeneratedBackend.DlRequest): Promise<void> {
        await post(`/v2/information/individual/usdl/${individualId}`, dto);
    }

    /**
     * HTTP GET /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getADriversLicense
     */
    async getADriversLicense(individualId: string): Promise<GeneratedBackend.IndividualResponseDto> {
        return get<GeneratedBackend.IndividualResponseDto>(`/v2/information/individual/usdl/${individualId}`, null, {
            disableToast: true
        });
    }
    /**
     * HTTP GET /api/v2/loanmanager/{id}/folders
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanFolders
     */
    async getLoanFolders(id: string): Promise<GeneratedBackend.FormElementsV2ResponseDto> {
        return get(`/v2/loanmanager/${id}/folders`);
    }

    /**
      * HTTP GET /api/v2/support/entries
      * Java method: ai.mysherpas.app.support.SupportController.getEntries
      */
    async getLearningCenterEntries(): Promise<GeneratedBackend.SupportResponseDto[]> {
        return get(`/v2/support/entries`);
    }

    /**
     * HTTP POST /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.createEntry
     */
    async createLearningCenterEntry(iDto: GeneratedBackend.SupportRequestDto): Promise<GeneratedBackend.SupportResponseDto> {
        const { data } = await post<GeneratedBackend.SupportResponseDto>(`/v2/support/entry`, iDto);
        return data;
    }

    /**
     * HTTP PUT /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.updateEntry
     */
    async updateLearningCenterEntry(iDto: GeneratedBackend.SupportRequestDto): Promise<GeneratedBackend.SupportResponseDto> {
        const { data } = await put<GeneratedBackend.SupportResponseDto>(`/v2/support/entry`, iDto);
        return data;
    }

    /**
     * HTTP DELETE /api/v2/support/entry/{id}
     * Java method: ai.mysherpas.app.support.SupportController.deleteEntry
     */
    async deleteLearningCenterEntry(id: string): Promise<void> {
        await deleteRequest(`/v2/support/entry/${id}`);
    }
    /**
     * HTTP GET /api/v2/tasks/consolidated/{loanId}
     * Java method: com.byzpass.demo.task.Task2Controller.consolidatedTasks
     */
    async consolidatedTasks(loanId: string): Promise<GeneratedBackend.ConsolidatedTasksDto> {
        return get(`/v2/tasks/consolidated/${loanId}`);
    }

    /**
     * HTTP GET /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.getPreference
     */
    async getPreference(key: string): Promise<GeneratedBackend.FePreferenceEntityResponseDto> {
        return get(`/v1/fepreference/${key}`, null, {
            disableToast: true
        });
    }

    /**
     * HTTP POST /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.savePreference
     */
    async savePreference(key: string, value: string) {
        return post(`/v1/fepreference/${key}`, value);
    }

    /**
     * HTTP GET /api/v1/loans/{loanId}/answers/{formElementId}
     * Java method: com.byzpass.demo.loan.LoanController.getAnswersForFormElement
     */
    async getAnswersForFormElement(loanId: string, formElementId: string): Promise<GeneratedBackend.AnswerV2Dto[]> {
        return get(`/v1/loans/${loanId}/answers/${formElementId}`);
    }
    async addEntityEquipmentInformation(entityId: string, equipment: GeneratedBackend.EquipmentRequestDto) {
        await post<GeneratedBackend.EquipmentResponseDto>(`/v2/information/equipment/${entityId}`, equipment);
    }

    async getEntityEquipmentInformation(entityId: string) {
        return get<GeneratedBackend.EquipmentResponseDto>(`/v2/information/equipment/${entityId}`);
    }

    async createEquipment(params: GeneratedBackend.EntityCreateDto): Promise<GeneratedBackend.EntityResponseDto> {
        const result = await post<GeneratedBackend.EntityResponseDto>('/v2/entities/equipment', params);
        return result.data;
    }

    async createBankAsset(params: GeneratedBackend.EntityCreateDto): Promise<GeneratedBackend.EntityResponseDto> {
        const result = await post<GeneratedBackend.EntityResponseDto>('/v2/entities/bankAsset', params);
        return result.data;
    }

    async addBankAssetInformation(entityId: string, bankAsset: GeneratedBackend.BankAssetRequestDto) {
        await post<GeneratedBackend.BankAssetResponseDto>(`/v2/information/bankAsset/${entityId}`, bankAsset);
    }

    async getBankAssetInformation(entityId: string) {
        return await get<GeneratedBackend.BankAssetResponseDto>(`/v2/information/bankAsset/${entityId}`);
    }

    async getRandomAssetInformation(entityId: string) {
        return await get<GeneratedBackend.RandomAssetResponseDto>(`/v2/information/randomAsset/${entityId}`);
    }

    async addRandomAssetInformation(entityId: string, randomAsset: GeneratedBackend.RandomAssetRequestDto) {
        await post<GeneratedBackend.RandomAssetResponseDto>(`/v2/information/randomAsset/${entityId}`, randomAsset);
    }

    async createRandomAssets(param: GeneratedBackend.EntityCreateDto): Promise<GeneratedBackend.EntityResponseDto> {
        const result = await post<GeneratedBackend.EntityResponseDto>('/v2/entities/randomAsset', param);
        return result.data;
    }

    /**
    * HTTP DELETE /api/v2/admin/maintenance
    * Java method: com.byzpass.demo.admin.AdminController.clearMaintenance
    */
    async clearMaintenance(): Promise<void> {
        await deleteRequest(`/v2/admin/maintenance`);
    }

    /**
     * HTTP GET /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminController.getMaintenance
     */
    async getMaintenance(): Promise<GeneratedBackend.MaintenanceWindowDto> {
        return get(`/v2/admin/maintenance`);
    }

    /**
     * HTTP POST /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminController.setMaintenance
     */
    async setMaintenance(dto: GeneratedBackend.MaintenanceWindowDto): Promise<void> {
        await post(`/v2/admin/maintenance`, dto);
    }

    /**
    * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingLoan
    * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingLoan
    */
    async applyNewTemplateToExistingLoan(iDto: GeneratedBackend.NewLoanTemplateDto): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>(`/v2/loanmanager/applyNewTemplateToExistingLoan`, iDto);
        return data;
    }

    /**
     * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingEntity
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingEntity
     */
    async applyNewTemplateToExistingEntity(iDto: GeneratedBackend.NewEntityTemplateDto): Promise<GeneratedBackend.LoanDto> {
        const { data } = await post<GeneratedBackend.LoanDto>(`/v2/loanmanager/applyNewTemplateToExistingEntity`, iDto);
        return data;
    }
    /*
       * HTTP PUT /api/v1/loans/{loanId}/setReviewStatus/{reviewStatus}
    * HTTP PUT /api/v1/loans/{loanId}/setReviewStatus/{reviewStatus}
    * Java method: com.byzpass.demo.loan.LoanController.setLoanReadyToReviewStatus
    */
    /**
     * HTTP PUT /api/v1/loans/{loanId}/setReviewStatus/{reviewStatus}
     * Java method: com.byzpass.demo.loan.LoanController.setLoanReadyToReviewStatus
     */
    async setLoanReadyToReviewStatus(loanId: string, reviewStatus: GeneratedBackend.LoanReviewStatus): Promise<GeneratedBackend.LoanDto> {
        const { data } = await put<GeneratedBackend.LoanDto>(`/v1/loans/${loanId}/setReviewStatus/${reviewStatus}`);
        return data;
    }
    /**
     * HTTP GET /api/v2/frontend/keys
     * Java method: com.byzpass.demo.util.frontend.FrontendKeys.getKeys
     */
    async get3PartyLicenseKeys(): Promise<GeneratedBackend.FeKeys> {
        return get(`/v2/frontend/keys`);
    }
    /**
     * HTTP GET /api/v1/lenders/companyDetails/{subdomain}
     * Java method: com.byzpass.demo.company.LenderController.getCompanyDetails
     */
    async getCompanyDetails(subdomain: string): Promise<GeneratedBackend.CompanyListDto> {
        return get<GeneratedBackend.CompanyListDto>(`/v1/lenders/companyDetails/${subdomain}`, null, {
            disableToast: true
        });
    }
    /**
     * HTTP GET /api/v1/lenders/subdomainLogoStub
     * Java method: com.byzpass.demo.company.LenderController.subdomainLogoStub
     */
    async getSubdomainLogoStub(queryParams?: { sub?: string; }): Promise<any> {
        return get(`/v1/lenders/subdomainLogoStub`, queryParams, {
            disableToast: true
        });
    }

    /**
     * HTTP GET /api/v2/templates/download/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForSelected
     */
    async downloadCsvForTemplate(id: string): Promise<string> {
        return get<string>(`/v2/templates/download/${id}`);
    }
    /**
     * HTTP GET /api/v1/lenders/{companyId}/excelTemplateId
     * HTTP PUT /api/v1/loans/{id}/unpin
     * Java method: com.byzpass.demo.loan.LoanController.unpinLoan
     */
    async unpinLoan(id: string): Promise<boolean> {
        const { data } = await put<boolean>(`/v1/loans/${id}/unpin`);
        return data;
    }

    /**
    * HTTP PUT /api/v1/loans/{id}/pin
    * Java method: com.byzpass.demo.loan.LoanController.pinLoan
    */
    async pinLoan(id: string): Promise<boolean> {
        const { data } = await put<boolean>(`/v1/loans/${id}/pin`);
        return data;
    }
    /**
    * HTTP GET /api/v1/lenders/{companyId}/excelTemplateId
     * Java method: com.byzpass.demo.company.LenderController.getExcelTemplateId
     */
    async getSourcesUsesExcelDocumentId(companyId: string): Promise<string> {
        return get<string>(`/v1/lenders/${companyId}/excelTemplateId`);
    }

    /**
     * HTTP PUT /api/v1/lenders/{companyId}/excelTemplateId/{documentId}
     * Java method: com.byzpass.demo.company.LenderController.setExcelTemplateId
     */
    async setSourcesUsesExcelDocumentId(companyId: string, documentId: string): Promise<string> {
        const { data } = await put<string>(`/v1/lenders/${companyId}/excelTemplateId/${documentId}`);
        return data;
    }

    /**
     * HTTP POST /api/v2/loanmanager/shared/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createSharedInfoElement
     */
    async createSharedInfoElement(iDto: GeneratedBackend.PackageInfoSharesRequestDto): Promise<GeneratedBackend.PackageInfoSharesResponseDto> {
        const { data } = await post<GeneratedBackend.PackageInfoSharesResponseDto>(`/v2/loanmanager/shared/elements`, iDto);
        return data;
    }

    /**
     * HTTP DELETE /api/v2/loanmanager/shared/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteSharedInfoElement
     */
    async deleteSharedInfoElement(iDto: GeneratedBackend.PackageInfoSharesRequestDto): Promise<void> {
        await deleteRequest(`/v2/loanmanager/shared/elements`, iDto);
    }
    /**
    * HTTP GET /api/v1/auth/timezones
     * HTTP POST /api/v2/conversion/image
     * Java method: ai.mysherpas.app.conversion.ConversionController.convertImage
     */
    async convertImage(requestDto: GeneratedBackend.ConversionRequestDto): Promise<GeneratedBackend.DeferredResult<GeneratedBackend.ResponseEntity<GeneratedBackend.ConversionResponseDto>>> {
        const { data } = await post<GeneratedBackend.DeferredResult<GeneratedBackend.ResponseEntity<GeneratedBackend.ConversionResponseDto>>>(`/v2/conversion/image`, requestDto);
        return data;
    }
    /** 
     * HTTP GET /api/v1/auth/timezones
     * Java method: com.byzpass.demo.security.SecurityAuthController.getTimezones
     */
    async getTimezones(): Promise<GeneratedBackend.TimezoneDTO[]> {
        return get<GeneratedBackend.TimezoneDTO[]>(`/v1/auth/timezones`);
    }

    /**
     *
     * HTTP PUT /api/v1/lenders/company/{id}
     * Java method: com.byzpass.demo.company.LenderController.updateCompany
     */
    async updateCompany(id: string, iOnboardCompany: GeneratedBackend.OnboardCompany): Promise<void> {
        await put(`/v1/lenders/company/${id}`, iOnboardCompany, {
            disableToast: true
        });
    }

    /**
     * HTTP GET /api/v2/templates/downloadCsv
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForUser
     */
    async downloadAllTemplatesCsvForUser(): Promise<string> {
        return get(`/v2/templates/downloadCsv`);
    }

    /**
     * HTTP GET /api/v2/templates/download/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForSelected
     */
    downloadCsvForSelected(id: string): Promise<string> {
        return get<string>(`/v2/templates/download/${id}`);
    }

    /**
     * HTTP GET /api/v2/templates/downloadMultiple
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadRequestedTemplates
     */
    async downloadRequestedTemplates(queryParams: { id: string[]; }): Promise<string> {
        // construct UrlSearchParams
        const params = new URLSearchParams();
        Object.keys(queryParams).forEach(key => {
            params.append(key, queryParams[key]);
        });
        return get(`/v2/templates/downloadMultiple`, params);
    }

    /**
     * HTTP GET /api/v1/noauth/version
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.getVersion
     */
    async getVersion(signal?: GenericAbortSignal): Promise<GeneratedBackend.VersionDto> {
        try {
            return get(`/v1/noauth/version`, {}, {
                signal,
                disableToast: true
            });
        } catch {
            return {
                gitHash: "development",
                version: "development",
            }
        }
    }
    /**
     * HTTP POST /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.generateElementsZip
     */
    async generateElementsZip(id: string, iDto: GeneratedBackend.DownloadJobRequestDto): Promise<GeneratedBackend.DownloadJobResponseDto> {
        const { data } = await post<GeneratedBackend.DownloadJobResponseDto>(`/v2/loanmanager/${id}/elementsDownload`, iDto);
        return data;
    }

    /**
     * HTTP GET /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getElementsZip
     */
    async getElementsZip(id: string, queryParams?: { jobid?: string; }): Promise<GeneratedBackend.DownloadJobsResponseDto> {
        return get(`/v2/loanmanager/${id}/elementsDownload`, queryParams);
    }

    /**
     * HTTP DELETE /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteElementsZip
     */
    async deleteElementsZip(id: string, queryParams: { jobid: string; }): Promise<GeneratedBackend.DownloadJobResponseDto> {
        const { data } = await deleteRequest<GeneratedBackend.DownloadJobResponseDto>(`/v2/loanmanager/${id}/elementsDownload?jobid=${queryParams.jobid}`, queryParams);
        return data
    }
    /*
    * HTTP GET /api/env
    * NextJS api route /api/vars
    */
    async getEnvVars(): Promise<Record<string, string>> {
        return get('/vars');
    }

    /**
     * HTTP GET /api/v2/users/loggedUser
     * Java method: com.byzpass.demo.security.AppUserController.getCheckLoggedUser
     */
    async getLoggedUser(): Promise<GeneratedBackend.OnboardDTO> {
        return get(`/v2/users/loggedUser`);
    }

    /**
     * HTTP POST /api/v1/loans/{id}/inviteborrower
     * Java method: com.byzpass.demo.loan.LoanController.inviteBorrowerUserToLoan
     */
    async inviteBorrowerUserToLoan(id: string, iInviteToLoanDTO: GeneratedBackend.InviteToLoanDTO): Promise<string> {
        const { data } = await post<string>(`/v1/loans/${id}/inviteborrower`, iInviteToLoanDTO);
        return data;
    }

    /**
     * HTTP GET /api/v2/entities/individualFromUser/{id}
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.getIndividualFromUser
     */
    async getIndividualFromUser(id: string): Promise<GeneratedBackend.IndividualResponseDto> {
        return get(`/v2/entities/individualFromUser/${id}`);
    }

    /**
    * HTTP POST /api/v2/information/individual/{entityId}
    * Java method: ai.mysherpas.app.sherpaentity.InformationController.postIndividualInformation
    */
    async postIndividualInformation(entityId: string, dto: GeneratedBackend.IndividualRequestDto): Promise<GeneratedBackend.ResponseEntity<any>> {
        const { data } = await post<GeneratedBackend.ResponseEntity<any>>(`/v2/information/individual/${entityId}`, dto);
        return data;
    }
    /**
 * HTTP POST /api/v2/loanmanager/createEntity
 * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createEntity
 */
    async createEntity(iDto: GeneratedBackend.CreateEntityCompleteDto): Promise<GeneratedBackend.EntityResponseDto> {
        const { data } = await post<GeneratedBackend.EntityResponseDto>(`/v2/loanmanager/createEntity`, iDto);
        return data;
    }

    /**
     * HTTP POST /api/v1/noauth/verifyConfirmationCode
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.verifyConfirmationCode
     */
    async verifyConfirmationCode(iDto: GeneratedBackend.AppUserForgotPasswordRequestDto): Promise<GeneratedBackend.VerifyConfirmationCodeDto> {
        const { data } = await post<GeneratedBackend.VerifyConfirmationCodeDto>(`/v1/noauth/verifyConfirmationCode`, iDto);
        return data;
    }

    /**
     * HTTP POST /api/v2/entities/individualFromUser
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createIndividualFromUser
     */
    async createIndividualFromUser(iDto: GeneratedBackend.IdDto): Promise<GeneratedBackend.IndividualResponseDto> {
        const { data } = await post<GeneratedBackend.IndividualResponseDto>(`/v2/entities/individualFromUser`, iDto);
        return data;
    }
    /**
     * HTTP PUT /api/v1/venture/export/loan/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.exportLoanToVenture
     */
    async exportLoanToVenture(loanId: string, signal?: GenericAbortSignal): Promise<void> {
        await put<void>(`/v1/venture/export/loan/${loanId}`, null, { signal, disableToast: true });
    }

    /**
     * HTTP POST /api/v2/users/{userId}/usDriversLicense
     * Java method: com.byzpass.demo.security.AppUserController.addDriversLicense
     */
    async addDriversLicense(userId: string, iUSDriversLicense: DeepPartial<GeneratedBackend.USDriversLicense>): Promise<GeneratedBackend.USDriversLicense> {
        const { data } = await post<GeneratedBackend.USDriversLicense>(`/v2/users/${userId}/usDriversLicense`, iUSDriversLicense);
        return data;
    }
}

export const api = new Api();
