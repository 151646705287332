import { FileRejection } from "react-dropzone";
import { Type } from "src/constants/form-element";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";

import { getFileExtension } from './get-file-extension';
import { getToastDuration } from "./get-toast-duration";
import { isAFile } from './is-a-file';
import { toast } from "./toast";

export const isDevelopmentMode = process.env.NODE_ENV === 'development';

// find a specific form element by id and return it with all it's parents as a flatt array
export const getElementLocation = (id: string, formElements: Pick<FormElementV2ResponseDtoExtended, 'id' | 'parentId' | 'title' | 'storageType' | 'loanId' | 'entityType'>[], ignoreCurrent: boolean = false): Pick<FormElementV2ResponseDtoExtended, 'id' | 'storageType' | 'parentId' | 'title' | 'loanId' | 'entityType'>[] => {
    const formElement = formElements.find(formElement => formElement.id === id);
    if (!formElement) return [];
    // if (!formElement.parentId) return [];
    // if form element is file only return parents
    // if ignoreCurrent is true, ignore the current form element
    if (formElement.storageType === Type.FILE || ignoreCurrent) {
        return getElementLocation(formElement.parentId, formElements)
    }
    return [formElement, ...getElementLocation(formElement.parentId, formElements)]
}


// find a specific form element by id and return it with all it's parents as a flatt array
export const findSectionByIdAndItsAncestors = (id: string, formElements: FormElementV2ResponseDtoExtended[], ignoreCurrent: boolean = false): FormElementV2ResponseDtoExtended[] => {
    const formElement = formElements.find(formElement => formElement.id === id);
    if (!formElement) return [];
    if (!formElement.parentId) return [];
    // if form element is file only return parents
    // if ignoreCurrent is true, ignore the current form element
    if (formElement.storageType === Type.FILE || ignoreCurrent) {
        return findSectionByIdAndItsAncestors(formElement.parentId, formElements)
    }
    return [formElement, ...findSectionByIdAndItsAncestors(formElement.parentId, formElements)]
}


const INVALID_FILE_MESSAGE = 'Invalid file type.  Please upload a valid file type.'

// Check dropped files are not folders
export const filterInvalidFiles = async (files: File[], rejectedFiles: FileRejection[]): Promise<{ acceptedFiles: File[], rejectedFiles: FileRejection[] }> => {
    const finalAcceptedFiles: File[] = [];
    const finalRejectedFiles: FileRejection[] = rejectedFiles
        .filter(rejectedFile => !['heic'].includes(getFileExtension(rejectedFile.file)))
        .map(rejectedFile => ({
            ...rejectedFile,
            errors: [
                {
                    message: INVALID_FILE_MESSAGE,
                    code: 'file-invalid-type'
                }
            ]
        }));
    for (const rejectedFile of rejectedFiles) {
        if (['heic'].includes(getFileExtension(rejectedFile.file))) {
            const { file } = rejectedFile;
            finalAcceptedFiles.push(file);
        }
    }
    for (const file of files) {
        const isValidFile = await isAFile(file);
        if (isValidFile && file.size > 0) {
            finalAcceptedFiles.push(file);
        } else if (!file || file.size === 0) {
            console.error('errored file uploaded', file.name, file.size)
            finalRejectedFiles.push({
                file,
                errors: [{
                    message: 'File is empty or folder has not be unzipped.  Please check file or unzip folder prior to uploading file',
                    code: 'file-too-small'
                }]
            });
        } else {
            finalRejectedFiles.push({
                file,
                errors: [
                    {
                        message: INVALID_FILE_MESSAGE,
                        code: 'file-invalid-type'
                    }
                ]
            });
        }
    }

    return ({
        acceptedFiles: finalAcceptedFiles,
        rejectedFiles: finalRejectedFiles
    });
}

// show toast for rejected dropped files
export const showRejectedDroppedFilesToast = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles?.length > 0) {
        const message = rejectedFiles.map(file => file.errors.map(error => error.message).join(', ')).join(', ');
        toast({
            content: message,
            type: 'error',
            duration: getToastDuration(message)
        });
    }
}


// return file name without extension
export const getFileNameWithoutExtension = (fileName: string): string => {
    return String(fileName).split('.').slice(0, -1).join('.');
}


// create url slug from string
export const createUrlSlug = (str: string): string => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return str.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}