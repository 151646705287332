// get File extension
export const getFileExtension = (file: File): string => {
    // if we don't have a file name, we can't get the extension
    if (!file) {
        return '';
    }
    // get file extension from file name eg file.pdf?extracted=3 should be file.pdf
    const fileExtension = file.name.split('?')[0].split('.').pop();
    if (!fileExtension) {
        throw new Error('File extension not found');
    }
    return fileExtension.toLowerCase();
}