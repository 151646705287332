import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { AppUserDTO2, AppUserSignupRequestDto, LoanDto, SimpleLabelDto } from "src/backend";
import { loanApi } from "src/services/loanApi";
import { AppThunkPromise, RootState } from "src/store";

import { api } from "../api";
import { getLoan } from "./loan";


interface AdminState {
    employees: Record<string, Record<string, AppUserDTO2>>;
    labels: Record<string, SimpleLabelDto[]>;
}

const initialState: AdminState = {
    employees: {},
    labels: {},
}

export const lenderSlice = createSlice({
    name: "lender",
    initialState,
    reducers: {
        setEmployees(state, action: PayloadAction<{ lenderId: string, employees: AppUserDTO2[] }>) {
            const { employees, lenderId } = action.payload;
            if (Array.isArray(employees)) {
                const employeesKeyedById = employees.reduce((acc, employee) => {
                    acc[employee.id] = employee;
                    return acc;
                }, {} as Record<string, AppUserDTO2>);

                if (!isEqual(state.employees[lenderId], employeesKeyedById)) {
                    state.employees[lenderId] = employeesKeyedById;
                }
            }
        },
        setLabels(state, action: PayloadAction<{ lenderId: string, labels: SimpleLabelDto[] }>) {
            const { labels, lenderId } = action.payload;
            if (!isEqual(state.labels[lenderId], labels)) {
                state.labels[lenderId] = labels;
            }
        }
    }
})

export const getLenderEmployees = ({ lenderId }: { lenderId: string }) => async (dispatch): Promise<void> => {
    const employees = await api.getCompanyEmployees({ lenderId });
    if (Array.isArray(employees)) {
        dispatch(lenderSlice.actions.setEmployees({
            lenderId,
            employees
        }));
    }
}

export const getLenderLabels = ({ lenderId }: { lenderId: string }) => async (dispatch): Promise<void> => {
    const labels = await api.getCompanyLabels({ lenderId });
    dispatch(lenderSlice.actions.setLabels({
        lenderId,
        labels
    }));
};


export const editUser = (id: string, appUser: AppUserSignupRequestDto) => async (dispatch, getState: () => RootState): Promise<AppUserDTO2> => {
    const user = await api.editUser(id, appUser);
    return user;
}

export const putLenderLabels = ({ lenderId, labels }: { lenderId: string, labels: SimpleLabelDto[] }) => async (dispatch): Promise<void> => {
    await api.putCompanyLabels({ lenderId, labels: { labels } });
    dispatch(getLenderLabels({ lenderId }));
};

export const deleteLenderLabelsById = ({ lenderId, labelIds }: { lenderId: string, labelIds: string[] }) => async (dispatch): Promise<void> => {
    await api.deleteCompanyLabels({ labelIds });
    dispatch(getLenderLabels({ lenderId }));
};

export const putLabelOnSomeone = ({ loanId, roleId, labels }: { loanId: string, roleId: string, labels: SimpleLabelDto[] }): AppThunkPromise<LoanDto> => async (dispatch): Promise<LoanDto> => {
    const loan = await api.putLabelOnSomeone(loanId, {
        labels: labels.map(label => label.id),
        loanRoleToSetFor: roleId
    });
    await dispatch(getLoan(loanId))
    dispatch(loanApi.util.invalidateTags(['SimpleLabelDto']));
    return loan;
};

export const lenderLabelsListSelector = (id: string) => createSelector((state: RootState) => state.lender.labels, (labels) => {
    return !!labels[id] ? Object.values(labels[id]) : [];
});

export const { reducer, actions } = lenderSlice;
