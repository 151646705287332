import { j as We } from "./jsx-runtime-B6kdoens.js";
import { a as k, _ as O, b as Be } from "./index-CWsRuimy.js";
import * as ze from "react";
import { forwardRef as R, useContext as Ue, useState as _, useEffect as P, createElement as f, useRef as T, createContext as Ye, useCallback as C, useMemo as je } from "react";
import { b as D, $ as Z, a as Xe } from "./index-B5chcmfZ.js";
import { $ as de, a as ie } from "./index-Cp-Nu9_j.js";
import { $ as W, a as Ge } from "./index-B--ic68G.js";
import { $ as Ke } from "./index-Bt4B6WE4.js";
import { $ as Ve } from "./index-rKUeoTl7.js";
import { u as qe, a as Ze, o as Je, s as Qe, l as et, f as tt, b as ot, c as nt, h as rt } from "./floating-ui.react-dom-vb4skoDG.js";
import { $ as lo } from "./index-Bixjd1-8.js";
import { $ as at } from "./index-DaaF0TLE.js";
import it from "react-dom";
import { $ as ue } from "./index-BbgZYt7X.js";
import { $ as st } from "./index-DJ_R-fw-.js";
import { c as ct } from "./utils-CJ9afRe1.js";
const V = "dismissableLayer.update", lt = "dismissableLayer.pointerDownOutside", dt = "dismissableLayer.focusOutside";
let se;
const ut = /* @__PURE__ */ Ye({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), pt = /* @__PURE__ */ R((t, o) => {
  var e;
  const { disableOutsidePointerEvents: n = !1, onEscapeKeyDown: r, onPointerDownOutside: a, onFocusOutside: s, onInteractOutside: d, onDismiss: i, ...p } = t, c = Ue(ut), [l, m] = _(null), u = (e = l == null ? void 0 : l.ownerDocument) !== null && e !== void 0 ? e : globalThis == null ? void 0 : globalThis.document, [, v] = _({}), g = k(
    o,
    (h) => m(h)
  ), $ = Array.from(c.layers), [x] = [
    ...c.layersWithOutsidePointerEventsDisabled
  ].slice(-1), b = $.indexOf(x), y = l ? $.indexOf(l) : -1, w = c.layersWithOutsidePointerEventsDisabled.size > 0, E = y >= b, I = ft((h) => {
    const L = h.target, z = [
      ...c.branches
    ].some(
      (M) => M.contains(L)
    );
    !E || z || (a == null || a(h), d == null || d(h), h.defaultPrevented || i == null || i());
  }, u), A = $t((h) => {
    const L = h.target;
    [
      ...c.branches
    ].some(
      (M) => M.contains(L)
    ) || (s == null || s(h), d == null || d(h), h.defaultPrevented || i == null || i());
  }, u);
  return Ke((h) => {
    y === c.layers.size - 1 && (r == null || r(h), !h.defaultPrevented && i && (h.preventDefault(), i()));
  }, u), P(() => {
    if (l)
      return n && (c.layersWithOutsidePointerEventsDisabled.size === 0 && (se = u.body.style.pointerEvents, u.body.style.pointerEvents = "none"), c.layersWithOutsidePointerEventsDisabled.add(l)), c.layers.add(l), ce(), () => {
        n && c.layersWithOutsidePointerEventsDisabled.size === 1 && (u.body.style.pointerEvents = se);
      };
  }, [
    l,
    u,
    n,
    c
  ]), P(() => () => {
    l && (c.layers.delete(l), c.layersWithOutsidePointerEventsDisabled.delete(l), ce());
  }, [
    l,
    c
  ]), P(() => {
    const h = () => v({});
    return document.addEventListener(V, h), () => document.removeEventListener(V, h);
  }, []), /* @__PURE__ */ f(W.div, O({}, p, {
    ref: g,
    style: {
      pointerEvents: w ? E ? "auto" : "none" : void 0,
      ...t.style
    },
    onFocusCapture: D(t.onFocusCapture, A.onFocusCapture),
    onBlurCapture: D(t.onBlurCapture, A.onBlurCapture),
    onPointerDownCapture: D(t.onPointerDownCapture, I.onPointerDownCapture)
  }));
});
function ft(t, o = globalThis == null ? void 0 : globalThis.document) {
  const e = Z(t), n = T(!1), r = T(() => {
  });
  return P(() => {
    const a = (d) => {
      if (d.target && !n.current) {
        let p = function() {
          pe(lt, e, i, {
            discrete: !0
          });
        };
        const i = {
          originalEvent: d
        };
        d.pointerType === "touch" ? (o.removeEventListener("click", r.current), r.current = p, o.addEventListener("click", r.current, {
          once: !0
        })) : p();
      } else
        o.removeEventListener("click", r.current);
      n.current = !1;
    }, s = window.setTimeout(() => {
      o.addEventListener("pointerdown", a);
    }, 0);
    return () => {
      window.clearTimeout(s), o.removeEventListener("pointerdown", a), o.removeEventListener("click", r.current);
    };
  }, [
    o,
    e
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => n.current = !0
  };
}
function $t(t, o = globalThis == null ? void 0 : globalThis.document) {
  const e = Z(t), n = T(!1);
  return P(() => {
    const r = (a) => {
      a.target && !n.current && pe(dt, e, {
        originalEvent: a
      }, {
        discrete: !1
      });
    };
    return o.addEventListener("focusin", r), () => o.removeEventListener("focusin", r);
  }, [
    o,
    e
  ]), {
    onFocusCapture: () => n.current = !0,
    onBlurCapture: () => n.current = !1
  };
}
function ce() {
  const t = new CustomEvent(V);
  document.dispatchEvent(t);
}
function pe(t, o, e, { discrete: n }) {
  const r = e.originalEvent.target, a = new CustomEvent(t, {
    bubbles: !1,
    cancelable: !0,
    detail: e
  });
  o && r.addEventListener(t, o, {
    once: !0
  }), n ? Ge(r, a) : r.dispatchEvent(a);
}
const fe = "Popper", [$e, ve] = de(fe), [vt, he] = $e(fe), ht = (t) => {
  const { __scopePopper: o, children: e } = t, [n, r] = _(null);
  return /* @__PURE__ */ f(vt, {
    scope: o,
    anchor: n,
    onAnchorChange: r
  }, e);
}, bt = "PopperAnchor", mt = /* @__PURE__ */ R((t, o) => {
  const { __scopePopper: e, virtualRef: n, ...r } = t, a = he(bt, e), s = T(null), d = k(o, s);
  return P(() => {
    a.onAnchorChange((n == null ? void 0 : n.current) || s.current);
  }), n ? null : /* @__PURE__ */ f(W.div, O({}, r, {
    ref: d
  }));
}), be = "PopperContent", [gt, ho] = $e(be), xt = /* @__PURE__ */ R((t, o) => {
  var e, n, r, a, s, d, i, p;
  const { __scopePopper: c, side: l = "bottom", sideOffset: m = 0, align: u = "center", alignOffset: v = 0, arrowPadding: g = 0, avoidCollisions: $ = !0, collisionBoundary: x = [], collisionPadding: b = 0, sticky: y = "partial", hideWhenDetached: w = !1, updatePositionStrategy: E = "optimized", onPlaced: I, ...A } = t, h = he(be, c), [L, z] = _(null), M = k(
    o,
    (N) => z(N)
  ), [K, Pe] = _(null), S = at(K), Te = (e = S == null ? void 0 : S.width) !== null && e !== void 0 ? e : 0, te = (n = S == null ? void 0 : S.height) !== null && n !== void 0 ? n : 0, Ce = l + (u !== "center" ? "-" + u : ""), Ee = typeof b == "number" ? b : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...b
  }, oe = Array.isArray(x) ? x : [
    x
  ], Oe = oe.length > 0, U = {
    padding: Ee,
    boundary: oe.filter(yt),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: Oe
  }, { refs: De, floatingStyles: ne, placement: _e, isPositioned: Y, middlewareData: H } = qe({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: Ce,
    whileElementsMounted: (...N) => Ze(...N, {
      animationFrame: E === "always"
    }),
    elements: {
      reference: h.anchor
    },
    middleware: [
      Je({
        mainAxis: m + te,
        alignmentAxis: v
      }),
      $ && Qe({
        mainAxis: !0,
        crossAxis: !1,
        limiter: y === "partial" ? et() : void 0,
        ...U
      }),
      $ && tt({
        ...U
      }),
      ot({
        ...U,
        apply: ({ elements: N, rects: ae, availableWidth: Me, availableHeight: Ne }) => {
          const { width: Fe, height: ke } = ae.reference, X = N.floating.style;
          X.setProperty("--radix-popper-available-width", `${Me}px`), X.setProperty("--radix-popper-available-height", `${Ne}px`), X.setProperty("--radix-popper-anchor-width", `${Fe}px`), X.setProperty("--radix-popper-anchor-height", `${ke}px`);
        }
      }),
      K && nt({
        element: K,
        padding: g
      }),
      wt({
        arrowWidth: Te,
        arrowHeight: te
      }),
      w && rt({
        strategy: "referenceHidden",
        ...U
      })
    ]
  }), [re, Ae] = me(_e), j = Z(I);
  ie(() => {
    Y && (j == null || j());
  }, [
    Y,
    j
  ]);
  const Le = (r = H.arrow) === null || r === void 0 ? void 0 : r.x, Re = (a = H.arrow) === null || a === void 0 ? void 0 : a.y, Ie = ((s = H.arrow) === null || s === void 0 ? void 0 : s.centerOffset) !== 0, [Se, He] = _();
  return ie(() => {
    L && He(window.getComputedStyle(L).zIndex);
  }, [
    L
  ]), /* @__PURE__ */ f("div", {
    ref: De.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...ne,
      transform: Y ? ne.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: Se,
      "--radix-popper-transform-origin": [
        (d = H.transformOrigin) === null || d === void 0 ? void 0 : d.x,
        (i = H.transformOrigin) === null || i === void 0 ? void 0 : i.y
      ].join(" ")
    },
    dir: t.dir
  }, /* @__PURE__ */ f(gt, {
    scope: c,
    placedSide: re,
    onArrowChange: Pe,
    arrowX: Le,
    arrowY: Re,
    shouldHideArrow: Ie
  }, /* @__PURE__ */ f(W.div, O({
    "data-side": re,
    "data-align": Ae
  }, A, {
    ref: M,
    style: {
      ...A.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: Y ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: (p = H.hide) !== null && p !== void 0 && p.referenceHidden ? 0 : void 0
    }
  }))));
});
function yt(t) {
  return t !== null;
}
const wt = (t) => ({
  name: "transformOrigin",
  options: t,
  fn(o) {
    var e, n, r, a, s;
    const { placement: d, rects: i, middlewareData: p } = o, l = ((e = p.arrow) === null || e === void 0 ? void 0 : e.centerOffset) !== 0, m = l ? 0 : t.arrowWidth, u = l ? 0 : t.arrowHeight, [v, g] = me(d), $ = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[g], x = ((n = (r = p.arrow) === null || r === void 0 ? void 0 : r.x) !== null && n !== void 0 ? n : 0) + m / 2, b = ((a = (s = p.arrow) === null || s === void 0 ? void 0 : s.y) !== null && a !== void 0 ? a : 0) + u / 2;
    let y = "", w = "";
    return v === "bottom" ? (y = l ? $ : `${x}px`, w = `${-u}px`) : v === "top" ? (y = l ? $ : `${x}px`, w = `${i.floating.height + u}px`) : v === "right" ? (y = `${-u}px`, w = l ? $ : `${b}px`) : v === "left" && (y = `${i.floating.width + u}px`, w = l ? $ : `${b}px`), {
      data: {
        x: y,
        y: w
      }
    };
  }
});
function me(t) {
  const [o, e = "center"] = t.split("-");
  return [
    o,
    e
  ];
}
const Pt = ht, Tt = mt, Ct = xt, Et = /* @__PURE__ */ R((t, o) => {
  var e;
  const { container: n = globalThis == null || (e = globalThis.document) === null || e === void 0 ? void 0 : e.body, ...r } = t;
  return n ? /* @__PURE__ */ it.createPortal(/* @__PURE__ */ f(W.div, O({}, r, {
    ref: o
  })), n) : null;
}), [G, bo] = de("Tooltip", [
  ve
]), J = ve(), Ot = "TooltipProvider", Dt = 700, q = "tooltip.open", [_t, Q] = G(Ot), At = (t) => {
  const { __scopeTooltip: o, delayDuration: e = Dt, skipDelayDuration: n = 300, disableHoverableContent: r = !1, children: a } = t, [s, d] = _(!0), i = T(!1), p = T(0);
  return P(() => {
    const c = p.current;
    return () => window.clearTimeout(c);
  }, []), /* @__PURE__ */ f(_t, {
    scope: o,
    isOpenDelayed: s,
    delayDuration: e,
    onOpen: C(() => {
      window.clearTimeout(p.current), d(!1);
    }, []),
    onClose: C(() => {
      window.clearTimeout(p.current), p.current = window.setTimeout(
        () => d(!0),
        n
      );
    }, [
      n
    ]),
    isPointerInTransitRef: i,
    onPointerInTransitChange: C((c) => {
      i.current = c;
    }, []),
    disableHoverableContent: r
  }, a);
}, ee = "Tooltip", [Lt, B] = G(ee), Rt = (t) => {
  const { __scopeTooltip: o, children: e, open: n, defaultOpen: r = !1, onOpenChange: a, disableHoverableContent: s, delayDuration: d } = t, i = Q(ee, t.__scopeTooltip), p = J(o), [c, l] = _(null), m = Ve(), u = T(0), v = s ?? i.disableHoverableContent, g = d ?? i.delayDuration, $ = T(!1), [x = !1, b] = Xe({
    prop: n,
    defaultProp: r,
    onChange: (A) => {
      A ? (i.onOpen(), document.dispatchEvent(new CustomEvent(q))) : i.onClose(), a == null || a(A);
    }
  }), y = je(() => x ? $.current ? "delayed-open" : "instant-open" : "closed", [
    x
  ]), w = C(() => {
    window.clearTimeout(u.current), $.current = !1, b(!0);
  }, [
    b
  ]), E = C(() => {
    window.clearTimeout(u.current), b(!1);
  }, [
    b
  ]), I = C(() => {
    window.clearTimeout(u.current), u.current = window.setTimeout(() => {
      $.current = !0, b(!0);
    }, g);
  }, [
    g,
    b
  ]);
  return P(() => () => window.clearTimeout(u.current), []), /* @__PURE__ */ f(Pt, p, /* @__PURE__ */ f(Lt, {
    scope: o,
    contentId: m,
    open: x,
    stateAttribute: y,
    trigger: c,
    onTriggerChange: l,
    onTriggerEnter: C(() => {
      i.isOpenDelayed ? I() : w();
    }, [
      i.isOpenDelayed,
      I,
      w
    ]),
    onTriggerLeave: C(() => {
      v ? E() : window.clearTimeout(u.current);
    }, [
      E,
      v
    ]),
    onOpen: w,
    onClose: E,
    disableHoverableContent: v
  }, e));
}, le = "TooltipTrigger", It = /* @__PURE__ */ R((t, o) => {
  const { __scopeTooltip: e, ...n } = t, r = B(le, e), a = Q(le, e), s = J(e), d = T(null), i = k(o, d, r.onTriggerChange), p = T(!1), c = T(!1), l = C(
    () => p.current = !1,
    []
  );
  return P(() => () => document.removeEventListener("pointerup", l), [
    l
  ]), /* @__PURE__ */ f(Tt, O({
    asChild: !0
  }, s), /* @__PURE__ */ f(W.button, O({
    // We purposefully avoid adding `type=button` here because tooltip triggers are also
    // commonly anchors and the anchor `type` attribute signifies MIME type.
    "aria-describedby": r.open ? r.contentId : void 0,
    "data-state": r.stateAttribute
  }, n, {
    ref: i,
    onPointerMove: D(t.onPointerMove, (m) => {
      m.pointerType !== "touch" && !c.current && !a.isPointerInTransitRef.current && (r.onTriggerEnter(), c.current = !0);
    }),
    onPointerLeave: D(t.onPointerLeave, () => {
      r.onTriggerLeave(), c.current = !1;
    }),
    onPointerDown: D(t.onPointerDown, () => {
      p.current = !0, document.addEventListener("pointerup", l, {
        once: !0
      });
    }),
    onFocus: D(t.onFocus, () => {
      p.current || r.onOpen();
    }),
    onBlur: D(t.onBlur, r.onClose),
    onClick: D(t.onClick, r.onClose)
  })));
}), ge = "TooltipPortal", [St, Ht] = G(ge, {
  forceMount: void 0
}), Mt = (t) => {
  const { __scopeTooltip: o, forceMount: e, children: n, container: r } = t, a = B(ge, o);
  return /* @__PURE__ */ f(St, {
    scope: o,
    forceMount: e
  }, /* @__PURE__ */ f(ue, {
    present: e || a.open
  }, /* @__PURE__ */ f(Et, {
    asChild: !0,
    container: r
  }, n)));
}, F = "TooltipContent", Nt = /* @__PURE__ */ R((t, o) => {
  const e = Ht(F, t.__scopeTooltip), { forceMount: n = e.forceMount, side: r = "top", ...a } = t, s = B(F, t.__scopeTooltip);
  return /* @__PURE__ */ f(ue, {
    present: n || s.open
  }, s.disableHoverableContent ? /* @__PURE__ */ f(xe, O({
    side: r
  }, a, {
    ref: o
  })) : /* @__PURE__ */ f(Ft, O({
    side: r
  }, a, {
    ref: o
  })));
}), Ft = /* @__PURE__ */ R((t, o) => {
  const e = B(F, t.__scopeTooltip), n = Q(F, t.__scopeTooltip), r = T(null), a = k(o, r), [s, d] = _(null), { trigger: i, onClose: p } = e, c = r.current, { onPointerInTransitChange: l } = n, m = C(() => {
    d(null), l(!1);
  }, [
    l
  ]), u = C((v, g) => {
    const $ = v.currentTarget, x = {
      x: v.clientX,
      y: v.clientY
    }, b = Wt(x, $.getBoundingClientRect()), y = Bt(x, b), w = zt(g.getBoundingClientRect()), E = Yt([
      ...y,
      ...w
    ]);
    d(E), l(!0);
  }, [
    l
  ]);
  return P(() => () => m(), [
    m
  ]), P(() => {
    if (i && c) {
      const v = ($) => u($, c), g = ($) => u($, i);
      return i.addEventListener("pointerleave", v), c.addEventListener("pointerleave", g), () => {
        i.removeEventListener("pointerleave", v), c.removeEventListener("pointerleave", g);
      };
    }
  }, [
    i,
    c,
    u,
    m
  ]), P(() => {
    if (s) {
      const v = (g) => {
        const $ = g.target, x = {
          x: g.clientX,
          y: g.clientY
        }, b = (i == null ? void 0 : i.contains($)) || (c == null ? void 0 : c.contains($)), y = !Ut(x, s);
        b ? m() : y && (m(), p());
      };
      return document.addEventListener("pointermove", v), () => document.removeEventListener("pointermove", v);
    }
  }, [
    i,
    c,
    s,
    p,
    m
  ]), /* @__PURE__ */ f(xe, O({}, t, {
    ref: a
  }));
}), [kt, mo] = G(ee, {
  isInside: !1
}), xe = /* @__PURE__ */ R((t, o) => {
  const { __scopeTooltip: e, children: n, "aria-label": r, onEscapeKeyDown: a, onPointerDownOutside: s, ...d } = t, i = B(F, e), p = J(e), { onClose: c } = i;
  return P(() => (document.addEventListener(q, c), () => document.removeEventListener(q, c)), [
    c
  ]), P(() => {
    if (i.trigger) {
      const l = (m) => {
        const u = m.target;
        u != null && u.contains(i.trigger) && c();
      };
      return window.addEventListener("scroll", l, {
        capture: !0
      }), () => window.removeEventListener("scroll", l, {
        capture: !0
      });
    }
  }, [
    i.trigger,
    c
  ]), /* @__PURE__ */ f(pt, {
    asChild: !0,
    disableOutsidePointerEvents: !1,
    onEscapeKeyDown: a,
    onPointerDownOutside: s,
    onFocusOutside: (l) => l.preventDefault(),
    onDismiss: c
  }, /* @__PURE__ */ f(Ct, O({
    "data-state": i.stateAttribute
  }, p, d, {
    ref: o,
    style: {
      ...d.style,
      "--radix-tooltip-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-tooltip-content-available-width": "var(--radix-popper-available-width)",
      "--radix-tooltip-content-available-height": "var(--radix-popper-available-height)",
      "--radix-tooltip-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-tooltip-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }), /* @__PURE__ */ f(Be, null, n), /* @__PURE__ */ f(kt, {
    scope: e,
    isInside: !0
  }, /* @__PURE__ */ f(st, {
    id: i.contentId,
    role: "tooltip"
  }, r || n))));
});
function Wt(t, o) {
  const e = Math.abs(o.top - t.y), n = Math.abs(o.bottom - t.y), r = Math.abs(o.right - t.x), a = Math.abs(o.left - t.x);
  switch (Math.min(e, n, r, a)) {
    case a:
      return "left";
    case r:
      return "right";
    case e:
      return "top";
    case n:
      return "bottom";
    default:
      throw new Error("unreachable");
  }
}
function Bt(t, o, e = 5) {
  const n = [];
  switch (o) {
    case "top":
      n.push({
        x: t.x - e,
        y: t.y + e
      }, {
        x: t.x + e,
        y: t.y + e
      });
      break;
    case "bottom":
      n.push({
        x: t.x - e,
        y: t.y - e
      }, {
        x: t.x + e,
        y: t.y - e
      });
      break;
    case "left":
      n.push({
        x: t.x + e,
        y: t.y - e
      }, {
        x: t.x + e,
        y: t.y + e
      });
      break;
    case "right":
      n.push({
        x: t.x - e,
        y: t.y - e
      }, {
        x: t.x - e,
        y: t.y + e
      });
      break;
  }
  return n;
}
function zt(t) {
  const { top: o, right: e, bottom: n, left: r } = t;
  return [
    {
      x: r,
      y: o
    },
    {
      x: e,
      y: o
    },
    {
      x: e,
      y: n
    },
    {
      x: r,
      y: n
    }
  ];
}
function Ut(t, o) {
  const { x: e, y: n } = t;
  let r = !1;
  for (let a = 0, s = o.length - 1; a < o.length; s = a++) {
    const d = o[a].x, i = o[a].y, p = o[s].x, c = o[s].y;
    i > n != c > n && e < (p - d) * (n - i) / (c - i) + d && (r = !r);
  }
  return r;
}
function Yt(t) {
  const o = t.slice();
  return o.sort((e, n) => e.x < n.x ? -1 : e.x > n.x ? 1 : e.y < n.y ? -1 : e.y > n.y ? 1 : 0), jt(o);
}
function jt(t) {
  if (t.length <= 1) return t.slice();
  const o = [];
  for (let n = 0; n < t.length; n++) {
    const r = t[n];
    for (; o.length >= 2; ) {
      const a = o[o.length - 1], s = o[o.length - 2];
      if ((a.x - s.x) * (r.y - s.y) >= (a.y - s.y) * (r.x - s.x)) o.pop();
      else break;
    }
    o.push(r);
  }
  o.pop();
  const e = [];
  for (let n = t.length - 1; n >= 0; n--) {
    const r = t[n];
    for (; e.length >= 2; ) {
      const a = e[e.length - 1], s = e[e.length - 2];
      if ((a.x - s.x) * (r.y - s.y) >= (a.y - s.y) * (r.x - s.x)) e.pop();
      else break;
    }
    e.push(r);
  }
  return e.pop(), o.length === 1 && e.length === 1 && o[0].x === e[0].x && o[0].y === e[0].y ? o : o.concat(e);
}
const Xt = At, Gt = Rt, Kt = It, ye = Nt, Vt = Xt, qt = Gt, Zt = Kt, Jt = Mt, we = ze.forwardRef(({ className: t, sideOffset: o = 4, ...e }, n) => /* @__PURE__ */ We.jsx(
  ye,
  {
    ref: n,
    sideOffset: o,
    className: ct(
      "z-tooltip overflow-hidden rounded-md border border-gray-neutral-80 bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      t
    ),
    ...e
  }
));
we.displayName = ye.displayName;
const go = Object.assign(qt, {
  Trigger: Zt,
  Content: we,
  Provider: Vt,
  Portal: Jt
});
export {
  go as T
};
