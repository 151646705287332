export const getFileNameWithoutExtension = (fileName: string): string => {
    // If the file name is empty, return it as is
    if (!fileName) {
        return fileName;
    }
    // Find the last dot in the file name
    const lastDotIndex = fileName.lastIndexOf('.');
    // If there is no dot in the file name, return the file name as is
    if (lastDotIndex === -1) {
        return fileName;
    }
    // Otherwise, return the file name without the extension
    return fileName.slice(0, lastDotIndex);
}